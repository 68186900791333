import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StateManagementService {
  userLogicParts: UserLogicPart[] = [];
  constructor() {
    this.userLogicParts.push(new UserLogicPart(1, 'UserScenario', true, []));
    this.userLogicParts.push(new UserLogicPart(2, 'Login', false, [false, false, false]));
    this.userLogicParts.push(new UserLogicPart(3, 'PatientList', false, []));
    this.userLogicParts.push(new UserLogicPart(4, 'CreateGuestPatient', false, []));
    this.userLogicParts.push(new UserLogicPart(5, 'UpdateGuestPatient', false, []));
    this.userLogicParts.push(new UserLogicPart(6, 'CreateNewPatient', false, [false]));
    this.userLogicParts.push(new UserLogicPart(7, 'PatientSMSPin', false, [false]));
    this.userLogicParts.push(new UserLogicPart(8, 'NewPatientList', false, [false]));
    this.userLogicParts.push(new UserLogicPart(9, 'ForgotPassword', false, [false]));
    this.userLogicParts.push(new UserLogicPart(10, 'ForgotPasswordSMSPin', false, [false]));
    this.userLogicParts.push(new UserLogicPart(11, 'ResetPassword', false, [false]));
    this.userLogicParts.push(new UserLogicPart(11, 'NewPatientErrorDialog', false, []));

  }

  enablePart = (_name: string) => {
    let index = this.userLogicParts.findIndex(x => x.Name === _name);
    this.userLogicParts[index].Enabled = true;
  }

  disablePart = (_name: string) => {
    let index = this.userLogicParts.findIndex(x => x.Name === _name);
    this.userLogicParts[index].Enabled = false;
  }

  clearParts = () => {
    this.userLogicParts.forEach((part, index) => {
      this.userLogicParts[index].Enabled = false;
    });
  }

  disablePartErrors = (_name: string) => {
    let index = this.userLogicParts.findIndex(x => x.Name === _name);
    this.userLogicParts[index].Messages.forEach((message, arrIndex) => {
      this.userLogicParts[index].Messages[arrIndex] = false;
    });
  }

  enablePartError = (_name: string, _errId: number) => {
    let index = this.userLogicParts.findIndex(x => x.Name === _name);
    this.userLogicParts[index].Messages[_errId - 1] = true;
  }

  clearPartsErrors = () => {
    this.userLogicParts.forEach(part => {
      part.Messages.forEach((message, index) => {
        part.Messages[index] = false;
      });
    });
  }


}

export class UserLogicPart {
  Id: number;
  Name: string;
  Enabled: boolean;
  Messages: boolean[] = [];

  constructor(_id: number, _name: string, _enabled: boolean, _messages: boolean[]) {
    this.Id = _id;
    this.Name = _name;
    this.Enabled = _enabled;
    this.Messages = _messages;
  }
}
