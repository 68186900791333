<div class="container">
    <div class="row">
        <br>
    </div>
    <div class="row">
        <br>
    </div>
    <div class="row">
        <br>
    </div>
    <div class="row">
        <br>
    </div>
    <div class="row">
        <br>
    </div>
    <div class="row">
        <br>
    </div>
    <div class="row">
        <br>
    </div>
    <div class="row">
        <br>
    </div>
    <div class="row">
        <br>
    </div>
    <div class="row">
        <br>
    </div>
    <div class="row">
        <div class="col-sm-3">

        </div>
        <div class="col-sm-6 text-center">
            <h1><strong>Eyemax Optical Studio</strong></h1><br>
            <h3><strong>500</strong></h3>
            <p>Internal Server Error</p>
            <p>{{errorMessage}}</p>
        </div>
        <div class="col-sm-3">

        </div>

    </div>
    <div class="row">
        <div class="col-sm-3">

        </div>
        <div class="col-sm-6 text-center">
            <a [routerLink]="['/appointment/covid19']" routerLinkActive="router-link-active" class="btn btn btn-info"
                role="button" aria-pressed="true" style="border-radius: 30px;">Book
                Appointment</a><br>
            <a href="https://www.eyemaxx.ca" class="btn   btn-primary" role="button" aria-pressed="true"
                style="background-color: blue ;border-radius: 30px;" target="_blank">Home Page</a>

        </div>
        <div class="col-sm-3">

        </div>

    </div>
    <div class="row">
        <br>
    </div>
</div>