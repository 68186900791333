export class Doctor {
    public firstName!: string;
    public lastName!: string;
    public description!: string;
    public id!: number;
    public designation!: string;
    public designationKey!: number;

    constructor() {

    }
}
