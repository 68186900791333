<div class="tab-pane" id="exam-type">
    <div class="row">
        <h4 class="info-text">Appointment Type</h4>
        <h4 class="info-text">Choose the appointment type that you would like to book from the list of exams below
        </h4>
        <div class="row">
            <div class="col-sm-3">
            </div>
            <div class="col-sm-6 text-center">

                <label class="control-label">Exam Type</label><br>
                <mat-form-field appearance="fill" style="width: 80%; margin-left: 4%;margin-right:4%;">
                    <mat-label>Click and scroll through our list of exams</mat-label>
                    <mat-select [(ngModel)]="SelectedExamId" (selectionChange)="SelectExam()"
                        [disabled]="isExamTypeSelectDisabled">
                        <mat-option *ngFor="let type of DoctorStoreTypeData.AppointmentTypes" [value]="type.id">{{
                            type.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <div *ngIf="ExamErrorMessage">
                    <mat-error>This type of exam can not be booked online,
                        Please select another type or call our
                        store to book an appointment.<a href="tel:613-216-6076">Tel:613.216.6076</a></mat-error>
                </div>
                <!-- <div class="form-group label-floating" style="margin-left: 4%; margin-right: 4%;">
                    <label class="control-label">Exam Type</label>

                    <select class="form-control" [(ngModel)]="SelectedExamId" (change)="SelectExam()">
                        <option value="0" selected disabled>Click here to select the exam type</option>
                        <option *ngFor="let type of DoctorStoreTypeData.AppointmentTypes" value="{{type.id}}">{{
                            type.name }}
                        </option>

                        <option value="-2">
                            Contact Lens Fitting: Multifocal or Monovision
                        </option>
                        <option value="-2">
                            Contact Lens Fitting: Single Vision
                        </option>
                        <option value="-2">
                            Contact Lens Teach: Multifocal or Monovision
                        </option>
                        <option value="-2">
                            Contact Lens Teach: Single Vision
                        </option>
                        <option value="-2">
                            Forms
                        </option>
                        <option value="-2">
                            Laser Post-op
                        </option>
                        <option value="-2">
                            NIHB (Non-Insured Health Benefits)
                        </option>
                        <option value="-2">
                            No Charge CL Follow Up
                        </option>
                        <option value="-2">
                            No Charge Rx Check
                        </option>
                        <option value="-2">
                            Partial Eye Exam
                        </option>
                        <option value="-2">
                            Retinal Images
                        </option>
                        <option value="-2">
                            Visual Field
                        </option>
                        <option value="-2">
                            OHIP: Partial (V408A)
                        </option>
                        <option value="-2">
                            OHIP: Visual Fields (V410A)
                        </option>
                        <option value="-2">
                            OHIP: ODSP (V450A)
                        </option>
                        <option value="-2">
                            OHIP: Ontario Works (V451A)
                        </option>
                        <option value="-2">
                            Optical: Sales Rep
                        </option>
                        <option value="-2">
                            Optical: Troubleshooting
                        </option>
                    </select>
                    <div *ngIf="ExamErrorMessage">
                        <mat-error>This type of exam can not be booked online,
                            Please select another type or call our
                            store to book an appointment.</mat-error>
                    </div>

                </div> -->

            </div>
            <div class="col-sm-3">

            </div>
        </div>

        <!-- <div class="col-sm-4">
            <li>Contact Lens Fitting: Multifocal or Monovision</li>
            <li>Contact Lens Fitting: Single Vision</li>
            <li>Contact Lens Teach: Multifocal or Monovision</li>
            <li>Contact Lens Teach: Single Vision</li>
            <li>NIHB (Non-Insured Health Benefits)</li>

        </div>
        <div class="col-sm-2">
            <li>Laser Post-op</li>
            <li>Forms</li>
            <li>Visual Field</li>
            <li>No Charge Rx Check</li>
            <li>Partial Eye Exam</li>
        </div>
        <div class="col-sm-3">
            <li>Retinal Images</li>
            <li>No Charge CL Follow Up</li>
            <li>OHIP: Partial (V402A)</li>
            <li>OHIP: Partial (V408A)</li>
            <li>OHIP: Medical 20-64 (V409A</li>
        </div>
        <div class="col-sm-3">
            <li>OHIP: Visual Fields (V410A)</li>
            <li>OHIP: ODSP (V450A)</li>
            <li>OHIP: Ontario Works (V451A)</li>
        </div> -->
        <h4 class="info-text" *ngIf="isOptomitrist">Please select an Optometrist / Any Optometrist</h4>
        <h4 *ngIf="StaffErrorMessage" class="info-text" style="color: red;">The selected staff doesnt have any available
            appointments, please select another one</h4>
            <div class="col-lg-10 col-sm-12 col-lg-offset-1 ng-star-inserted doctorslist" *ngIf="isOptomitrist">
            <div class="col-sm-1">
            </div>
            <div class="col-sm-2 AnyOptomitrist" *ngIf="isOptomitrist" (click)="SelectStaff(-1)">
                <div class="choice " data-toggle="wizard-radio " rel="tooltip" title="Any Optometrist"
                    [class.active]="SelectedStaff.id === -1">
                    <input type="radio" name="job" value="Code">
                    <div class="icon">
                        <img src="assets\img\icon-02.png" width="100%" height="100%">
                    </div>
                    <h6>Any Optometrist</h6>
                </div>
            </div>

            <div class="col-sm-2 Optomitrist" *ngFor="let s of Staffs" (click)="SelectStaff(s.id)">
                <div class="choice" data-toggle="wizard-radio" rel="tooltip" title="{{s.description}}"
                    [class.active]="SelectedStaff.id === s.id">
                    <input type="radio" name="job" value="Design">
                    <div class="icon doctor-circle">
                        <img src="assets\img\{{s.firstName}}{{s.lastName}}.jpg" width="100%" height="100%"
                            class="img-circle">
                    </div>
                    <h6>{{s.firstName}} {{s.lastName}}</h6>
                </div>
            </div>

        </div>
    </div>
    <div class="row">

    </div>
    <div class="row" *ngIf="isOptomitristSpinnerEnabled">
        <div class="col-sm-4"></div>
        <div class="col-sm-4" style="text-align: center;">
            <p-progressSpinner [style]="{width: '50px', height: '50px'}" styleClass="custom-spinner" strokeWidth="5"
                fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>
        </div>
        <div class="col-sm-4"></div>

    </div>
</div>