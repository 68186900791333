export class Emaildata {
  firstName!: string;
  lastName!: string;
  streetName!: string;
  streetNumber!: string;
  city!: string;
  province!: string;
  postalCode!: string;
  dateOfBirth!: string;
  cell!: string;
  email!: string;
  medicalCardExp!: string;
  medicalCard!: string;
  medicalCardVersion!: string;
  appointmentType!: string;
  optimtrist!: string;
  appointmentDate!: string;
  opticianAppointment!: string;
  isWheel!: string;

  constructor() {

  }
}
