export class CreatePatientBody {
    public firstName!: string;
    public lastName!: string;
    public email!: string;
    public dateOfBirth!: string;
    public phone!: string;
    public cell!: string;
    public languagePreference!: string;
    public streetName!: string;
    public version!: string;
    public city!: string;
    public country!: string;
    public medicalCard!: string;
    public unit!: string;
    public medicalCardExp!: string;
    public postalCode!: string;
    public province!: string;
    public gender!: number;
    public streetNumber!: number;
    public isDefaultSms!: boolean;
    public isDefaultEmail!: boolean;


    constructor() {
    }

}
