export class Store {
    public country!: number;
    public province!: string;
    public address2!: string;
    public city!: string;
    public address1!: string;
    public timezone!: string;
    public postalCode!: string;
    public name!: string;
    public timeZone!: string;
    public id!: number;

    constructor() {

    }
}
