<div class="tab-pane" id="summary">
  <!-- <h4 class="text-center" *ngIf="WheelChairFormEnabled">Additional Information</h4>
  <div class="summary-options" *ngIf="WheelChairFormEnabled">
    <div class="checkbox-inline" style="margin-left: 3%;margin-right: 3%;margin-top: 20px;">
      <label><input type="checkbox" [(ngModel)]="WheelChairChecked">
        <h4 style="font-size: 15px;
        font-weight: normal;
        text-align: initial;color: black;"> Yes, I would like to request a wheelchair accessible room.</h4>
      </label>
    </div>
    <div class="checkbox-inline" style="margin-left: 3%;margin-right: 3%;margin-top: 20px;"
      *ngIf="appointmentTypeData.isOptomitrist">
      <label><input type="checkbox" [(ngModel)]="OpticianAppointment">
        <h4 style="font-size: 15px;
        font-weight: normal;
        text-align: initial;color: black;">Yes, I would like to request an appointment to shop for glasses.</h4>
      </label>
    </div>
  </div>
  <div class="row" style="display: flex;justify-content:center;" *ngIf="WheelChairFormEnabled">
    <button type="button" class="btn btn-primary" (click)="onWheelchairButtonSubmit()">Next</button>
  </div> -->

  <div class="row text-center" *ngIf="AppointmentSummaryEnabled">
    <!-- <h4 class="info-text">Appointment Summary</h4> -->
    <h4 style="color: #575757;" class="info-text">
      Please confirm the information and complete your booking below</h4>
    <div class="row">
      <div class="col-md-2">
        <br>
      </div>
      <!--  -->
      <!-- <div class="col-md-7" *ngIf="appointmentTypeData.isOptomitrist">
        <mat-checkbox [(ngModel)]="OpticianAppointment" (change)="onOpticianAppointmentChange()" [color]="'primary'"
          style="margin-left: 3%;margin-right: 3%;">
          <h4 style="font-size: 15px;
          font-weight: normal;
          text-align: initial;color: black;">Yes, I would like to request an appointment to shop for glasses.</h4>
        </mat-checkbox>
      </div> -->
      <div class="col-md-3">
        <br>
      </div>
    </div>
    <br>
  </div>
  <div class="row" *ngIf="AppointmentSummaryEnabled">
    <div class="col-md-12 col-md-offset-1">
      <div class="col-md-5">
        <p><strong>First Name:
          </strong>{{
          appointmentConfirmationData.SelectedUser.firstName}}</p>
        <p><strong>Last Name:
          </strong>{{
          appointmentConfirmationData.SelectedUser.lastName}}</p>
        <p><strong>Date of Birth:
          </strong>{{
          appointmentConfirmationData.SelectedUser.dateOfBirth}}</p>
        <p><strong>Email:
          </strong>{{
          appointmentConfirmationData.SelectedUser.email}}
        </p>
        <p><strong>Cell Phone:
          </strong>{{
          appointmentConfirmationData.SelectedUser.cell}}</p>
      </div>
      <div class="col-md-6">
        <p><strong>{{appointmentTypeData.isOptomitrist?'Optometrist:':'Optician:'}}
          </strong>{{appointmentTypeData.isOptomitrist ||appointmentTypeData.isEdgeDown
          ?SelectedDoctor.firstName+' '+SelectedDoctor.lastName : appointmentTypeData.ExamType.name }}</p>
        <p><strong>Exam Type: </strong>{{appointmentTypeData.ExamType.name}}</p>
        <p><strong>Appointment Date & Time: </strong>{{appointmentSlotData.SelectedDate | date:'mediumDate'}}
          {{appointmentSlotData.SelectedTimeSlot.start}} - {{appointmentSlotData.SelectedTimeSlot.end}}</p>
        <p><strong>Insurance Company: </strong> {{
          appointmentConfirmationData.SelectedUser.medicalCard?
          appointmentConfirmationData.SelectedUser.medicalCard:'Not Available'}}</p>
        <p><strong>Health Card & Expiry: </strong>{{
          appointmentConfirmationData.SelectedUser.medicalCardExp?
          appointmentConfirmationData.SelectedUser.medicalCardExp:'Not Available'
          }}
        </p>
      </div>
    </div>
    <div class="col-md-1">
    </div>
  </div>

  <div class="row" style="display: flex;justify-content: center;margin-top:20px ;" *ngIf="AppointmentSummaryEnabled">
    <div class="checkbox-inline" style="padding: 4% 13%;">
      <label><input type="checkbox" [(ngModel)]="TermsOfPrivacyApproved" (change)="OnTermsOfPrivacyChange()">
        <h4 style="font-size: 15px;
        font-weight: normal;
        text-align: initial;color: black;">By booking an appointment, you confirm that you agree to our <a
            style="color: rgb(121,160,223);" href="https://appointment-eyemaxx.ca/TermsOfPolicyService/TermsofUse.pdf"
            target="blank">Terms
            of Use</a> and <a style="color: rgb(121,160,223);"
            href="https://appointment-eyemaxx.ca/TermsOfPolicyService/PrivacyPolicy.pdf" target="blank">Privacy
            Policy</a> and
          to
          receive communication from Eyemaxx Optical Studio. You can unsubscribe at any time.</h4>
      </label>
    </div>
  </div>
</div>



<p-dialog class="text-left" header=" " [(visible)]="WheelChairFormEnabled" [modal]="true" [style]="{width: '50vw'}"
  [baseZIndex]="10000" [draggable]="false" [resizable]="false" [position]="'center'" [closable]="true"
  [showHeader]="false">
  <h4 class="text-center" style="margin-top:20px">Additional Information</h4>
  <div class="summary-options">
    <div class="checkbox-inline" style="margin-left: 3%;margin-right: 3%;margin-top: 20px;"
      *ngIf="appointmentTypeData.isOptomitrist">
      <label><input type="checkbox" [(ngModel)]="OpticianAppointment">
        <h4 style="font-size: 15px;
        font-weight: normal;
        text-align: initial;color: black;">Yes, I would like to request an appointment to shop for glasses.</h4>
      </label>
    </div>
    <div class="checkbox-inline" style="margin-left: 3%;margin-right: 3%;margin-top: 20px;">
      <label><input type="checkbox" [(ngModel)]="WheelChairChecked">
        <h4 style="font-size: 15px;
        font-weight: normal;
        text-align: initial;color: black;"> Yes, I would like to request a wheelchair accessible room.</h4>
      </label>
    </div>
  </div>
  <div class="row" style="display: flex;justify-content:center;">
    <button type="button" class="btn btn-primary" style="color: white;background-color: #071D49;"
      (click)="onWheelchairButtonSubmit()">Next</button>
  </div>
</p-dialog>
