<div class="tab-pane" id="date-time">
    <div class="row">
        <h4 class="info-text">Choose the appointment date and time</h4>
        <h4 class="info-text" *ngIf="!isLoadingSpinnerEnabled">Select Date</h4>
        <div style="text-align: center;" *ngIf="isLoadingSpinnerEnabled">
            <p-progressSpinner></p-progressSpinner>
        </div>
        <div class="col-sm-3">
        </div>

        <!-- <div class="col-sm-5">
            <img src="../../../../assets/img/1.PNG" width="100%" height="100%">
        </div> -->
        <div class="col-sm-6" *ngIf="!isLoadingSpinnerEnabled">
            <div class="p-col-6">
                <p-calendar [(ngModel)]="SelectedDate" [inline]="true" [showWeek]="false"
                    [numberOfMonths]="ViewedMonths" (onSelect)="onCallendarSelect()" [minDate]="MinDate"
                    [maxDate]="MaxDate" [disabledDates]="InvalidDates">
                </p-calendar>
            </div>

        </div>
        <div class="col-sm-3">
        </div>
    </div>

    <div class="row">
        <div class="row text-center" *ngIf="ActiveSlots.length>0">
            <h4 class="info-text">Select time slot</h4>
            <div class="col-sm-3">

            </div>
            <div class="col-sm-6">
                <div class="col-sm-4 col-lg-3" *ngFor="let slot of ActiveSlots" (click)="onSlotSelect(slot)">
                    <a style="padding: 10px 12px!important;min-width: 100px!important;"
                        class="btn   hello btn-fill btn-danger btn-wd" [class.clickedButton]="slot.id == SelectedSlot.id"
                        [class.defaultButton]="slot.id != SelectedSlot.id">{{formatSlot(slot.start)}}
                        -
                        {{formatSlot(slot.end)}} </a>
                </div>
                <div class="col-sm-3">

                </div>

            </div>
        </div>
    </div>
</div>