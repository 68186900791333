import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { OnlineBookingModuleModule } from './online-booking-module/online-booking-module.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxMaskModule } from 'ngx-mask';
import { StateManagementService } from './services/state-management.service';
import { StepsManagementService } from './online-booking-module/services/steps-management.service';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    OnlineBookingModuleModule,
    BrowserAnimationsModule,

  ],
  providers: [StateManagementService, StepsManagementService],
  bootstrap: [AppComponent]
})
export class AppModule { }
