import { environment } from "src/environments/environment";

export class PatientLoginRequest {
  loginEmail!: string;
  password!: string;
  accountsId!: number;

  constructor() {
    this.accountsId = environment.accountsId
  }
}


export class ULPatient {
  firstName!: string;
  lastName!: string;
  patientId!: number;
  dob!: string;


  constructor() {

  }
}

export class UserLoginResponse {
  PatientList: ULPatient[] = [];
  Token!: string;
}


export class ForgotPasswordRequest {
  cell!: string;
  accountsId!: number;

  constructor() {

  }
}

export class ValidateSMSPinRequest {
  accountsId!: number;
  cell!: string;
  smsPinCodeResetPassword!: string;

  constructor() {

  }
}


export class ResetPasswordRequest {
  accountsId!: number;
  token!: string;
  newPassword!: string;
  ipAddress: string;
  emailOrCell!: string;

  constructor() {
    this.ipAddress = '135.19.198.194';
  }
}


export class NewUserBookAppointment {

  //patient info
  public firstName!: string;
  public lastName!: string;
  public email!: string;
  public cell!: string;
  public gender!: string;
  public dateOfBirth!: string;
  public phone!: string;

  public streetName!: string;
  public streetNumber!: string;
  public city!: string;
  public postalCode!: string;
  public country!: string;
  public province!: string;
  public countryId!: string;
  public unit!: string;
  public medicalCard!: string;
  public medicalCardExp!: string;
  public medicalCardVersion!: string;
  public version!: string;
  public languagePreference!: string;
  public id!: string;

  //apointment info
  public password!: string;
  public smsPinCode!: string;
  public earlyRequest: boolean;
  public earlyRequestComment: string;
  public appointmentTypeId: number;
  public storeId: number;
  public doctorId: string;
  public slotId: number;
  public accountsId!: number;
  public appointmentModification: string;
  public oldappointmentId: string;
  public forceCreatePatient: boolean = false;
  public isDefaultSms: boolean = true;
  public patientId: string;
  constructor() {
    this.earlyRequest = false;
    this.earlyRequestComment = "";
    this.appointmentTypeId = -1;
    this.storeId = -1;
    this.doctorId = "";
    this.slotId = -1;
    this.appointmentModification = "false";
    this.oldappointmentId = "";
    this.patientId = '0';
    this.countryId = '1';
    this.gender = '1';
  }
}


export class TokenBeforeAppointment {
  cell!: string;
  email!: string;
  accountsId!: number;
  storeId!: number;
  password!: string;
  forceCreatePatient!: boolean;
  existingPatientId!: string;

  constructor() {
    this.forceCreatePatient = false;
    this.accountsId = environment.accountsId;
    this.storeId = 1;
    // this.existingPatientId = '0';
  }
}


//Midleware objects


export class MWLoginBody {

  username: string;
  password: string;

  constructor() {
    this.username = environment.USERNAME;
    this.password = environment.PASSWORD;
  }
}


export class AddTransactionBody {
  AppID!: number;
  OppID!: number;
  Response!: string;
  RequestDate!: string;
  Status!: string;
  RequestHeader!: string;
  RequestBody!: string;
  id!: number;



  constructor() {

  }
}



export class AddExceptionBody {
  AppID!: number;
  OppID!: number;
  TransactionId!: number;
  Application!: string;
  ExceptionMessage!: string;
  ExceptionTrace!: string;
  ExceptionDate!: string;


  constructor() {

  }
}


export class SendTokenPatient {
  firstName!: string;
  lastName!: string;
  patientId!: number;
  dob!: Date | null;
  cell!: string;
  email!: string;
  constructor() {
  }
}




export class CreatePatientProfile {
  accountsId!: number;
  cell!: string;
  city!: string;
  email!: string;
  existingPatientId!: string; //"0"
  firstName!: string;
  isDefaultSms: boolean = true;
  lastName!: string;
  medicalCard!: string
  originLink!: string;
  password!: string;
  passwordConfirm!: string
  phone!: string;
  postalCode!: string;
  smsPinCode!: string;
  streetName!: string;
  streetNumber!: string;
  unit!: string;
  username!: string;
  province!: string;
  dateOfBirth!: string;
  medicalCardExp!: string;
  medicalCardVersion!: string;

  constructor() {
    this.existingPatientId = '0';
    this.accountsId = environment.accountsId;
  }

}
