import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpClient,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { BookingModuleService } from '../services/booking-module-service.service';
import { AddExceptionBody, AddTransactionBody, MWLoginBody } from '../models/4thNewImplementation';
import { catchError, map, switchMap } from 'rxjs/operators';

@Injectable()
export class MWInterceptorInterceptor implements HttpInterceptor {

  MiddleWareLoginURL: string = environment.MiddlewareURL + '/login';
  MWURL: string = environment.MiddlewareURL;
  NonInterceptedRequests: string[] = [
    this.MWURL + '/addTransaction',
    this.MWURL + '/addException',
  ]
  constructor(private serv: BookingModuleService, private http: HttpClient) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request);
    if (request.url === this.MiddleWareLoginURL) {
      return next.handle(request);
    }
    else if (this.NonInterceptedRequests.includes(request.url)) {
      return this.serv.MiddlewareLogin().pipe(
        switchMap((response) => {
          request = request.clone({
            setHeaders: {
              'Content-Type': 'application/json',
              'Accept': 'application/json,*/*',
              'Authorization': 'jwt ' + response
            }

          });
          return next.handle(request);
        })
      )
    }
    else {
      if (request.method == 'GET') {
        let T: AddTransactionBody = new AddTransactionBody();
        T.AppID = environment.APPID;
        T.OppID = 1;
        return next.handle(request).pipe(map((e: HttpEvent<any>) => {
          if (e instanceof HttpResponse) {
            let today: Date = new Date();
            T.RequestDate = today.toISOString().split('T')[0] + ' ' + today.toISOString().split('T')[1].split('.')[0];
            T.Status = 'Success';
            T.Response = JSON.stringify(e.body);
            T.RequestHeader = request.method + ' ' + request.url + request.params;
            T.RequestBody = "GET Request No Body Needed";
            this.serv.MiddlewareAddTransaction(T).subscribe(x => {
            });
          }
          return e;
        }), catchError((error: HttpErrorResponse) => {
          let T: AddTransactionBody = new AddTransactionBody();
          let TR: AddTransactionBody = new AddTransactionBody();
          T.AppID = environment.APPID;
          T.OppID = 1;
          let today: Date = new Date();
          T.RequestDate = today.toISOString().split('T')[0] + ' ' + today.toISOString().split('T')[1].split('.')[0];
          T.Status = 'Failed';
          T.Response = 'Error No Http Response Available';
          T.RequestHeader = request.method + ' ' + request.url + ' ' + request.params;
          T.RequestBody = 'GET Request No Body Needed';
          this.serv.MiddlewareAddTransaction(T).subscribe(x => {
            TR = Object.assign(TR, x);
            let EX: AddExceptionBody = new AddExceptionBody();
            EX.AppID = environment.APPID;
            EX.OppID = 2;
            EX.Application = environment.USERNAME;
            EX.TransactionId = TR.id;
            EX.ExceptionDate = today.toISOString().split('T')[0] + ' ' + today.toISOString().split('T')[1].split('.')[0];
            EX.ExceptionMessage = error.status + ' ' + error.message;
            EX.ExceptionTrace = error.status + ' ' + error.message;
            this.serv.MiddlewareAddException(EX).subscribe(y => {
            });
          });
          return throwError(error);
        }));
      } else {
        let T: AddTransactionBody = new AddTransactionBody();
        T.AppID = environment.APPID;
        T.OppID = 1;
        return next.handle(request).pipe(map((e: HttpEvent<any>) => {
          if (e instanceof HttpResponse) {
            let today: Date = new Date();
            T.RequestDate = today.toISOString().split('T')[0] + ' ' + today.toISOString().split('T')[1].split('.')[0];
            T.Status = 'Success';
            T.Response = JSON.stringify(e.body);
            T.RequestHeader = request.method + ' ' + request.url + request.params;
            T.RequestBody = JSON.stringify(request.body);
            this.serv.MiddlewareAddTransaction(T).subscribe(x => {
            });
          }
          return e;
        }), catchError((error: HttpErrorResponse) => {
          let T: AddTransactionBody = new AddTransactionBody();
          let TR: AddTransactionBody = new AddTransactionBody();
          T.AppID = environment.APPID;
          T.OppID = 1;
          let today: Date = new Date();
          T.RequestDate = today.toISOString().split('T')[0] + ' ' + today.toISOString().split('T')[1].split('.')[0];
          T.Status = 'Failed';
          T.Response = 'Error No Http Response Available';
          T.RequestHeader = request.method + ' ' + request.url + ' ' + request.params;
          T.RequestBody = JSON.stringify(request.body);
          this.serv.MiddlewareAddTransaction(T).subscribe(x => {
            TR = Object.assign(TR, x);
            let EX: AddExceptionBody = new AddExceptionBody();
            EX.AppID = environment.APPID;
            EX.OppID = 2;
            EX.Application = environment.USERNAME;
            EX.TransactionId = TR.id;
            EX.ExceptionDate = today.toISOString().split('T')[0] + ' ' + today.toISOString().split('T')[1].split('.')[0];
            EX.ExceptionMessage = error.status + ' ' + error.message;
            EX.ExceptionTrace = error.status + ' ' + error.message;
            this.serv.MiddlewareAddException(EX).subscribe(y => {
            });
          });
          return throwError(error);
        }));
      }

    }
  }
}
