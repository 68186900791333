// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  ApiUrl: 'https://azurefd.downloadwink.com/Web',
  accountsId: 1922,
  storeId: 1,
  companyName: 'Eyemaxx Optical Studio',
  // ApiUrl: ' https://testing.orderoptical.com/Web',

  //Middleware related
  APPID: 2,
  USERNAME: 'EyeMax',
  PASSWORD: 'THVyaWVyI09wdGljYWxfUEAkJHcwcmRAMjAyMQ==',
  MiddlewareURL: 'https://www.kreativedevelopment.com:9090'
  // MiddlewareURL: 'https://66.94.102.72:443'
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.


// export const environment = {
//   production: false,
//   ApiUrl: 'https://onlinebooking.downloadwink.com/Web',
//   accountsId: 1922,
//   storeId: 1,
//   companyName: 'Eyemaxx Optical Studio',
//   //ApiUrl: ' https://testing.orderoptical.com/Web',

//   //Middleware related
//   APPID: 2,
//   USERNAME: 'EyeMax',
//   PASSWORD: 'THVyaWVyI09wdGljYWxfUEAkJHcwcmRAMjAyMQ==',
//   MiddlewareURL: 'https://www.kreativedevelopment.com:9090'
//   // MiddlewareURL: 'https://66.94.102.72:443'
// };
