<div class="tab-pane" id="covid19">
  <form [formGroup]="CovidPreScrForm">
    <div class="row">
      <!-- <div class="col-sm-12">
                <h4 class="info-text">COVID-19 Pre-screening</h4>
                <h5 class="info-text" style="color: red;"
                    *ngIf="( f.firstOption.errors) ||(f.secondOption.errors) || (f.thirdOption.errors) || (f.fourthOption.errors) || (f.fifthOption.errors)">
                    Please check all the boxes to continue</h5>
            </div> -->

      <div class="col-sm-1">

      </div>
      <div class="col-sm-10">

        <h5 class="info-text" style="color: #575757;"
          *ngIf=" (f.fifthOption.errors)">
          Please check all the boxes to continue</h5>
        <h4 class="info-text">Symptoms Pre-Screening</h4>
        <div class="col-sm-12 ">
          <mat-checkbox class="example-margin" formControlName="fifthOption" [color]="'primary'">I am not
            experiencing urgent symptoms such as: redness, itching, swelling, pain, discharge, blurred
            vision, vision loss, floaters, flashers. If you are, please call to book your appointment by
            clicking on the number below.</mat-checkbox>


          <!-- <div class="checkbox">
                        <label>
                            <input type="checkbox" name="covid4" formControlName="fourthOption">
                        </label>I am not experiencing symptoms such as: redness, itching, swelling, pain, discharge,
                        blurred
                        vision, vision loss, floaters, flashers
                    </div> -->
        </div>
<!--
        <div class="col-sm-12 ">
          <h4 class="info-text">COVID-19 Pre-Screening</h4>
          <mat-checkbox class="example-margin" formControlName="firstOption" [color]="'primary'">
            I do not have fever, cough, shortness of breath, cold or flu-like symptoms, or any other
            sudden changes to my health.
          </mat-checkbox>

        </div>
        <div class="col-sm-12 ">
          <mat-checkbox class="example-margin" formControlName="secondOption" [color]="'primary'"> I have
            not tested positive for COVID-19 in the past 10 days or been told to isolate.</mat-checkbox>


        </div>
        <div class="col-sm-12 ">
          <mat-checkbox class="example-margin" formControlName="thirdOption" [color]="'primary'"> I have not
            had close contact with a positive case of COVID-19, or I am fully vaccinated, asymptomatic & not
            living with the positive case.</mat-checkbox>

        </div>
        <div class="col-sm-12 ">
          <mat-checkbox class="example-margin" formControlName="fourthOption" [color]="'primary'"> I have
            not travelled outside of Canada in the past 14 days, or I am fully vaccinated.</mat-checkbox>


        </div> -->

        <!-- <div>
                    You Must Choose Option or Call Our Store.
                </div> -->

      </div>

      <div class="col-sm-1">
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <br>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-4">

      </div>
      <div class="col-sm-4 text-center">

        <p id="store-name-header">Eyemaxx Optical Studio</p>
        <p id="address-par">Address: 237 Elgin St Ottawa, ON K2P 1L6
        </p>
        <a  href="mailto:staff@eyemaxx.ca" target="_blank">
          <p id="email-link">Email: staff@eyemaxx.ca</p>
        </a>
        <p id="phone-par">Phone: <a href="tel:613-216-6076">613.216.6076</a></p>
        <a id="get_dire" target="_blank" class="btn btn-next hello btn-fill btn-danger btn-wd"
          href="https://www.google.com/maps/place/Eyemaxx+Optical+Studio/@45.4187597,-75.6931724,17z/data=!3m2!4b1!5s0x4cce05a97c9f1ddf:0xe0b6f9110f255594!4m5!3m4!1s0x4cce05a9635628f5:0x126623ec6dff4430!8m2!3d45.4187597!4d-75.6909837">Get
          Directions</a>
      </div>
      <div class="col-sm-4">

      </div>

    </div>
  </form>
</div>
