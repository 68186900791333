export class SinglePatient {
    public firstName!: string;
    public lastName!: string;
    public birthdate!: string;
    public id!: number;

    constructor() {

    }
}
