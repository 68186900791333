import { NewUserBookAppointment, TokenBeforeAppointment, ULPatient } from "../4thNewImplementation";
import { Patient } from "../create-patient/patient";
import { PatientListResponse } from "../get-patients/patient-list-response";
import { DataParent } from "./data-parent";

export class AppointmentConfirmationData extends DataParent {

  SelectedUser!: Patient;
  UsersList!: PatientListResponse;
  SelectedPatientId!: number;
  constructor(_order: number, _type: string) {
    super(_order, _type);
  }

}
