import { SinglePatient } from "./patient";

export class PatientListResponse {
    public patientList: SinglePatient[] = [];

    Initialize(data: any): SinglePatient[] {
        var list: any[] = data["patientList"];
        list.forEach((_element: any) => {
            let p: SinglePatient = new SinglePatient();
            p.firstName = _element.firstName;
            p.lastName = _element.lastName;
            p.birthdate = _element.birthdate;
            p.id = +(<string>_element.id).split('-')[1];
            this.patientList.push(p);
        });
        return this.patientList;
    }


    constructor() {

    }
}
