<div class="most-top-header">
  <div class="header-container">
    <div class="header-contact-item">
      <i class="fa-regular fa-clock"></i>&nbsp;
      <a href="/contacts/">Open: Monday-Sunday.</a>
    </div>
    <div class="header-contact-item-2">
      <i class="fa-solid fa-envelope"></i>&nbsp;
      <a href="mailto:staff@eyemaxx.ca">staff@eyemaxx.ca</a>
    </div>
    <div class="social-items-container">
      <a href="https://www.facebook.com/eyemaxx613/"><i class="fa-brands fa-facebook-f"></i></a>
      <a href="https://twitter.com/eyemaxx613"><i class="fa-brands fa-twitter"></i></a>
      <a href="https://www.instagram.com/eyemaxx613/"> <i class="fa-brands fa-instagram"></i></a>
    </div>
  </div>

</div>


<!-- <div class="second-header">
  <div class="row second-header-container">
    <div class="col-lg-3 col-sm-12 text-center">
      <a href="https://www.eyemaxx.ca/" itemprop="url" class="logo2">
        <img src="../../../../assets/img/eyemax-logo.png" alt="Eyemaxx Optical Studio" itemprop="logo" class="">
      </a>
    </div>
  </div>


</div> -->




<!--
<div style="width: 100%; background-color: #071D49; height: 45px;">
  <div class="col-sm-4">

  </div>
  <div class="col-sm-4" style="text-align: center;padding: 10px;">
    <a href="https://www.eyemaxx.ca/pages/our-covid-19-policies" style="color: white;"><strong>Updated COVID
        Policies</strong></a>
  </div>
  <div class="col-sm-4">

  </div>
</div> -->
<!-- <div class="row" style="margin-top: 2%;"> -->
<!-- <div class="row"> -->
<div class="container-menu-header-v2 p-t-26" style="background-color:white;">

  <div class="wrap_header">
    <!-- Menu -->
    <div class="wrap_menu">
      <nav class="menu">
        <ul class="main_menu">
          <li>
            <a href="https://www.eyemaxx.ca/" itemprop="url" class="logo2">
              <img src="../../../../assets/img/eyemax-logo.png" alt="Eyemaxx Optical Studio" itemprop="logo" class="">
            </a>
          </li>

          <li>
            <a> <span> ABOUT US</span></a>
            <ul class="sub_menu">
              <li>
                <a href="https://eyemaxx.ca/our-story/" target="blank">Our Story</a>
              </li>
              <li>
                <a href="https://eyemaxx.ca/our-doctors/" target="blank">Our Doctors</a>
              </li>
              <li>
                <a href="https://eyemaxx.ca/our-team/" target="blank">Our Team</a>
              </li>
              <li>
                <a href="https://eyemaxx.ca/our-team/" target="blank">Contact Us</a>
              </li>
            </ul>
          </li>
          <li>
            <a> <span>SERVICES</span></a>
            <ul class="sub_menu">
              <li>
                <a href="https://eyemaxx.ca/services/eye-exams-vision-tests/" target="blank">Eye Exams & Vision Tests</a>
              </li>
              <li>
                <a href="https://eyemaxx.ca/services/glasses-and-sunglasses/" target="blank">Eyeware Services</a>
              </li>
              <li>
                <a href="https://eyemaxx.ca/services/insurance-billing/" target="blank">Insurance Billing</a>
              </li>
            </ul>
          </li>
          <!-- <li class="">
            <a href="https://www.eyemaxx.ca/pages/book-an-eye-exam"><span>Book An
                Appointment</span></a>
            <ul class="sub_menu">

              <li><a class="" href="https://www.eyemaxx.ca/pages/book-an-eye-exam">Book an Eye
                  Exam</a></li>

              <li><a class="" href="https://www.eyemaxx.ca/pages/book-an-optical-appointment">Book an
                  Optical Service</a></li>

            </ul>
          </li> -->
          <li>
            <a> <span>COLLECTIONS</span></a>
            <ul class="sub_menu">
              <li>
                <a href="https://eyemaxx.ca/glasses-sunglasses/" target="blank">Glasses & Sunglasses</a>
              </li>
              <li>
                <a href="https://eyemaxx.ca/lenses/" target="blank">Lenses</a>
              </li>
              <li>
                <a href="https://eyemaxx.ca/contact-lenses-2/" target="blank">Contact Lenses</a>
              </li>
            </ul>
          </li>

          <li>
            <a> <span>LEARNING CENTRE</span></a>
            <ul class="sub_menu">
              <li>
                <a href="https://eyemaxx.ca/faq/" target="blank">#AskEyemaxx</a>
              </li>
              <li>
                <a href="https://eyemaxx.ca/ive-been-framed/" target="blank">Blog</a>
              </li>
<!--              <li>-->
<!--                <a href="http://eyemaxx.itsnerd.ca/covied-19/">COVID-19</a>-->
<!--              </li>-->
            </ul>
          </li>

          <li>
            <a> <span>SHOP</span></a>
            <ul class="sub_menu">
              <li>
                <a href="https://eyemaxx.ca/contact-lens-request/" target="blank">Order Contact Lenses</a>
              </li>
              <li>
                <a href="https://eyemaxx.ca/coming-soon/" target="blank">Coming Soon...!</a>
              </li>
            </ul>
          </li>
          <!-- <li class="">
            <a href="https://www.eyemaxx.ca/pages/ourteam">About Us</a>
            <ul class="sub_menu">

              <li><a class="" href="https://www.eyemaxx.ca/pages/ourteam">Team</a></li>

              <li><a class="" href="https://www.eyemaxx.ca/pages/optometrists">Eye doctors</a></li>

            </ul>
          </li>
          <li class="">
            <a href="https://www.eyemaxx.ca/pages/our-services">Services</a>
            <ul class="sub_menu">

              <li><a class="" href="https://www.eyemaxx.ca/pages/our-services">Eye Exams &amp; Vision
                  Tests</a></li>

              <li><a class="" href="https://www.eyemaxx.ca/pages/contact-lenses-and-fitting">Contact
                  Lenses &amp; Fitting</a></li>

              <li><a class="" href="https://www.eyemaxx.ca/pages/optical-services">Optical
                  Services</a></li>

              <li><a class="" href="https://www.eyemaxx.ca/pages/our-covid-19-policies">Our COVID
                  Policies</a></li>

            </ul>
          </li>
          <li class="">
            <a href="https://www.eyemaxx.ca/pages/our-brands">Products</a>
            <ul class="sub_menu">

              <li><a class="" href="https://www.eyemaxx.ca/pages/lenses-brands">Lenses</a></li>

              <li><a class="" href="https://www.eyemaxx.ca/pages/frames">Frames</a></li>

              <li><a class="" href="https://www.eyemaxx.ca/pages/contact-lenses-and-fitting">Contact
                  Lenses</a></li>

            </ul>
          </li>
          <li class="">
            <a href="https://www.eyemaxx.ca/blogs/ive-been-framed">Blog</a>
          </li>
          <li class="">
            <a href="https://www.eyemaxx.ca/pages/contact-us">Contact Us</a>
          </li> -->
        </ul>
      </nav>
    </div>
    <!-- Header Icon -->
    <div class="header-icons">
    </div>
  </div>
</div>
<div class="wrap_header_mobile">
  <!-- Logo moblie -->
  <a href="https://www.eyemaxx.ca/" class="logo-mobile">
    <img src="../../../../assets/img/mobile_logo.png" alt="Eyemaxx Optical Studio">
  </a>
  <!-- Button show menu -->
  <div class="btn-show-menu">
    <!-- Header Icon mobile -->
    <div class="header-icons-mobile">
      <a href="https://www.eyemaxx.ca/account/login" class="header-wrapicon1 dis-block">
        <img src="../../../../assets/img/icon-header-01.png" class="header-icon1" alt="ICON">
      </a>
      <span class="linedivide2"></span>
      <img src="../../../../assets/img/icon-header-02.png" class="header-icon1 js-show-header-dropdown" alt="ICON">
      <span class="header-icons-noti cart-total">0</span>
      <!-- Header cart noti -->
      <div class="header-cart header-dropdown">
        <ul class="header-cart-wrapitem">
          <li>
            <p class="text-center cart-empty">Your shopping cart is empty!</p>
          </li>
        </ul>
      </div>
    </div>
    <div class="btn-show-menu-mobile hamburger hamburger--squeeze">
      <span class="hamburger-box">
        <span class="hamburger-inner"></span>
      </span>
    </div>
  </div>
</div>

<div class="wrap-side-menu">
  <nav class="side-menu">
    <ul class="main-menu">
      <li class="item-topbar-mobile p-l-20 p-t-8 p-b-8">
        <span class="topbar-child1">
          <p style="text-align: center; font-size:17px; color: white"><a style="text-decoration:underline; color:white"
              href="https://www.eyemaxx.ca/pages/our-covid-19-policies">Updated COVID Policies</a></p>
        </span>
      </li>
      <li class="item-topbar-mobile p-l-20 p-t-8 p-b-8">
        <div class="topbar-child2-mobile">

          <span class="topbar-email">
            <p style="font-size:17px; color:#888;"><a style="color:#888;"
                href="mailto:staff@eyemaxx.ca?subject=Questions" title="">staff@eyemaxx.ca</a> | Tel:
              613.216.6076</p>
          </span>
          <div class="topbar-language rs1-select2">
            <span class="selected-currency" style="display:none;">CAD</span>
          </div>
        </div>
      </li>
      <li class="item-topbar-mobile p-l-10">
        <div class="topbar-social-mobile">
          <a href="https://www.facebook.com/eyemaxx613/" class="topbar-social-item fa fa-facebook"
            title="Eyemaxx Optical Studio on Facebook">
          </a>
          <a data-toggle="tooltip" class=" topbar-social-item fa fa-twitter" data-placement="bottom"
            href="https://twitter.com/eyemaxx613" title="" data-original-title="Eyemaxx Optical Studio on Twitter">
          </a>
          <a data-toggle="tooltip" class="topbar-social-item fa fa-instagram" data-placement="bottom"
            href="https://www.instagram.com/eyemaxx613/" title=""
            data-original-title="Eyemaxx Optical Studio on Instagram">
          </a>
        </div>
      </li>
      <li class="item-menu-mobile ">
        <a href="https://www.eyemaxx.ca/pages/book-an-eye-exam"><span
            style="color:#79a0df; font-size:15px; font-weight:800">Book An Appointment</span></a>
        <ul class="sub-menu">
          <li><a class="" href="https://www.eyemaxx.ca/pages/book-an-eye-exam">Book an Eye Exam</a></li>
          <li><a class="" href="https://www.eyemaxx.ca/pages/book-an-optical-appointment">Book an Optical
              Service</a></li>
        </ul>
        <i class="arrow-main-menu fa fa-angle-right" aria-hidden="true"></i>
      </li>
      <li class="item-menu-mobile ">
        <a href="https://www.eyemaxx.ca/pages/ourteam">About Us</a>
        <ul class="sub-menu">
          <li><a class="" href="https://www.eyemaxx.ca/pages/ourteam">Team</a></li>
          <li><a class="" href="https://www.eyemaxx.ca/pages/optometrists">Eye doctors</a></li>
        </ul>
        <i class="arrow-main-menu fa fa-angle-right" aria-hidden="true"></i>
      </li>
      <li class="item-menu-mobile ">
        <a href="https://www.eyemaxx.ca/pages/our-services">Services</a>
        <ul class="sub-menu">
          <li><a class="" href="https://www.eyemaxx.ca/pages/our-services">Eye Exams &amp; Vision Tests</a>
          </li>
          <li><a class="" href="https://www.eyemaxx.ca/pages/contact-lenses-and-fitting">Contact Lenses &amp;
              Fitting</a></li>
          <li><a class="" href="https://www.eyemaxx.ca/pages/optical-services">Optical Services</a></li>
          <li><a class="" href="https://www.eyemaxx.ca/pages/our-covid-19-policies">Our COVID Policies</a>
          </li>
        </ul>
        <i class="arrow-main-menu fa fa-angle-right" aria-hidden="true"></i>
      </li>
      <li class="item-menu-mobile ">
        <a href="https://www.eyemaxx.ca/pages/our-brands">Products</a>
        <ul class="sub-menu">
          <li><a class="" href="https://www.eyemaxx.ca/pages/lenses-brands">Lenses</a></li>
          <li><a class="" href="https://www.eyemaxx.ca/pages/frames">Frames</a></li>
          <li><a class="" href="https://www.eyemaxx.ca/pages/contact-lenses-and-fitting">Contact Lenses</a>
          </li>
        </ul>
        <i class="arrow-main-menu fa fa-angle-right" aria-hidden="true"></i>
      </li>
      <li class="item-menu-mobile ">
        <a href="https://www.eyemaxx.ca/blogs/ive-been-framed">Blog</a>
      </li>
      <li class="item-menu-mobile ">
        <a href="https://www.eyemaxx.ca/pages/contact-us">Contact Us</a>
      </li>
    </ul>
  </nav>
</div>


<!-- </div> -->

<div class="container">

  <div id="base_div" class="col-sm-12">
    <!--      Wizard container        -->
    <div class="wizard-container">
      <div class="card wizard-card" data-color="red" id="wizard">
        <div class="wizard-header" style="background-image: url('assets/img/cover.jpg')">
          <h3 id="wizard-main-title" class=" wizard-title" style="color:#79a0df">
            Book an Appointment with Eyemaxx Optical Studio
          </h3>
          <h5>Please follow the steps to book an appointment for Eye Exams and Optical Services</h5>
        </div>
        <div class="wizard-navigation">
          <ul>
            <li id="step1" [class.liDisabled]="!stepsEnabled(1)" (click)="navigate(1)"><a href="#covid19"
                data-toggle="tab">STEP 1: PRE-SCREENING</a>
            </li>
            <!-- <li id="step2" [class.liDisabled]="!stepsEnabled(2)" (click)="navigate(2)"><a
                                        href="#appointment-type" data-toggle="tab">Step 2 (Service Type)</a></li> -->
            <li id="step3" [class.liDisabled]="!stepsEnabled(2)" (click)="navigate(2)"><a href="#exam-type"
                data-toggle="tab">Step 2 :
                Appointment Type</a>
            </li>
            <li id="step4" [class.liDisabled]="!stepsEnabled(3)" (click)="navigate(3)"><a href="#date-time"
                data-toggle="tab">Step 3 : Date and Time</a></li>
            <!-- <li id="step5" [class.liDisabled]="!stepsEnabled(4)" (click)="navigate(4)"><a
                                        href="#optician-appointment" data-toggle="tab">Step 4 (Optician
                                        Appointment)</a></li> -->
            <li id="step6" [class.liDisabled]="!stepsEnabled(4)" (click)="navigate(4)"><a href="#confirm"
                data-toggle="tab">Step 4 :
                Patient Information</a></li>
            <li id="step7" [class.liDisabled]="!stepsEnabled(5)" (click)="navigate(5)"><a href="#summary"
                data-toggle="tab">Step 5 :
                Summary</a></li>
          </ul>
        </div>

        <div class="tab-content">
          <router-outlet (activate)="onActivate($event)"></router-outlet>
        </div>
        <!-- <div class="wizard-footer" *ngIf="currentStepStatus().validated">
                            <div class="pull-right">
                                <input type='button' class='btn btn-next btn-fill btn-danger btn-wd' name='next' (click)="navigate(currentStepStatus().order+1)"
                                    value='Next' />
                                <input type='button' class='btn btn-finish btn-fill btn-danger btn-wd' name='finish'
                                    value='Finish' />
                            </div>
                            <div class="pull-left" *ngIf="currentStepStatus().order != 1">
                                <input type='button' class='btn btn-previous btn-fill btn-default btn-wd'
                                    name='previous' value='Previous' />
                            </div>
                            <div class="clearfix"></div>
                        </div> -->
        <!-- <div class="wizard-footer" >
                            <div class="pull-right">
                                <input type='button' class='btn btn-next btn-fill btn-danger btn-wd' name='next'
                                    value='Next' />
                                <input type='button' class='btn btn-finish btn-fill btn-danger btn-wd' name='finish'
                                    value='Finish' />
                            </div>
                            <div class="pull-left">
                                <input type='button' class='btn btn-previous btn-fill btn-default btn-wd'
                                    name='previous' value='Previous' />
                            </div>
                            <div class="clearfix"></div>
                        </div> -->
        <div id="next-previous-div" class="wizard-footer">
          <div class="pull-right">
            <button type='button' class='btn btn-next btn-hello btn-fill btn-danger btn-wd liDisabled module-button'
              (click)="navigate(currentStepStatus().order+1)" [class.liDisabled]="!currentStepStatus().validated">

              Next
              <span>
                <img style="margin-left: 10px;" src="../../../../assets/icons/Icons/next-arrow.svg" alt="">

              </span></button>

            <button type='button' class='btn btn-finish btn-fill btn-danger btn-wd module-button'
              (click)="BookAppointment()" [class.liDisabled]="CheckAllStepsValidity()==false">

              Confirm and
              Book
              <span>
                <img style="margin-left: 10px;" src="../../../../assets/icons/Icons/next-arrow.svg" alt="">

              </span>
            </button>

          </div>

          <div class="pull-left" *ngIf="currentStepStatus().order != 1">

            <button type='button' class='btn btn-previous btn-fill btn-default btn-wd module-button'
              (click)="navigate(currentStepStatus().order-1)">
              <span>
                <img src="../../../../assets/icons/Icons/pre-arrow.svg" alt="" style="    margin-right: 10px;">
              </span>
              Previous


            </button>

          </div>
          <div class="clearfix"></div>
        </div>
      </div>
    </div>
    <!-- wizard container -->
  </div>

  <!-- row -->
  <!--  big container -->
</div>

<div class="footer">
  <footer id="footer" class="bg6 p-t-45 p-b-43 p-l-45 p-r-45">
    <div class="footer__columns flex-w p-b-90">
      <div class="w-size6 p-t-30 p-l-15 p-r-15 respon3">
        <h4 class="s-text12 p-b-30">
          <!-- GET IN TOUCH -->
        </h4>
        <div>
          <p class="s-text7 w-size27">
            <img style="width:200px; margin-bottom:20px;" src="../../../../assets/img/saved_resource.png">
            <br> GET IN TOUCH
            <br>
            <a class="contact-us-main-titles" href="mailto:staff@eyemaxx.ca?subject=Questions"
              title="">staff@eyemaxx.ca</a> <br>
            <a class="contact-us-main-titles" href="tel:613.216.6076">
              Tel: 613.216.6076
            </a>
          </p>
          <div class="footer__social-icons flex-m p-t-30">





            <a data-toggle="tooltip" class="fs-18 color1 p-r-20 footer-social-button" data-placement="bottom"
              href="https://twitter.com/eyemaxx613" title="" data-original-title="Eyemaxx Optical Studio on Twitter">
              <img class="social-icon" src="../../../../assets/icons/Icons/twitter.svg" alt="">
            </a>




            <a href="https://www.facebook.com/eyemaxx613/" class="fs-18 color1 p-r-20 footer-social-button"
              title="Eyemaxx Optical Studio on Facebook">
              <img class="social-icon" src="../../../../assets/icons/Icons/facebook.svg" alt="">
            </a>




            <a data-toggle="tooltip" class="fs-18 color1 p-r-20 footer-social-button" data-placement="bottom"
              href="https://www.instagram.com/eyemaxx613/" title=""
              data-original-title="Eyemaxx Optical Studio on Instagram">
              <img class="social-icon" src="../../../../assets/icons/Icons/instagram.svg" alt="">
            </a>






          </div>
        </div>
      </div>
      <div class="w-size7 p-t-30 p-l-15 p-r-15 respon4">
        <!-- <h4 class="s-text12 p-b-30">Quick Links</h4> -->
        <ul>

          <li class="p-b-9 s-text7"><a class="footer-links" href="https://www.eyemaxx.ca/pages/book-an-eye-exam">Book An
              Appointment</a></li>

          <li class="p-b-9 s-text7"><a class="footer-links" href="https://www.eyemaxx.ca/pages/ourteam">ABOUT US</a>
          </li>

          <li class="p-b-9 s-text7"><a class="footer-links"
              href="https://www.eyemaxx.ca/pages/our-services">SERVICES</a>
          </li>

          <li class="p-b-9 s-text7"><a class="footer-links" href="https://www.eyemaxx.ca/pages/our-brands">PRODUCTS</a>
          </li>

          <li class="p-b-9 s-text7"><a class="footer-links" href="https://www.eyemaxx.ca/blogs/ive-been-framed">BLOG</a>
          </li>

          <li class="p-b-9"><a href="https://www.eyemaxx.ca/pages/contact-us" class="footer-links">CONTACT US</a></li>
        </ul>
      </div>
      <div class="w-size7 p-t-30 p-l-15 p-r-15 respon4">
        <h4 class="s-text12 p-b-30"></h4>
        <ul>

        </ul>
      </div>
      <div class="w-size7 p-t-30 p-l-15 p-r-15 respon4">
        <h4 class="s-text12 p-b-30"></h4>
        <ul>

        </ul>
      </div>
      <div class="w-size8 p-t-30 p-l-15 p-r-15 respon3">




      </div>
    </div>
    <div class="t-center p-l-15 p-r-15">
      <div class="t-center s-text7 p-t-20 copyright-text">
        Copyright ©️ 2022 Eyemaxx Optical Studio All rights reserved
      </div>
    </div>
  </footer>
</div>



<!-- <p-confirmDialog [style]="{width: '50vw'}" key="positionDialog" [position]="'top'" [baseZIndex]="10000">
</p-confirmDialog> -->

<p-toast></p-toast>
<p-dialog class="text-left" header=" " [(visible)]="showBookAppointmentPopUp" [modal]="true" [style]="{width: '50vw'}"
  [baseZIndex]="10000" [draggable]="false" [resizable]="false" [position]="'top'" [closable]="false">
  <!-- <i class="pi pi-check-circle" style="font-size: 2rem;float: left;"></i> -->
  <div class="text-left">
    <p [ngStyle]="{'color': bookingSuccess!==true?'red':'black'}">
      <i class="{{popIconType}}" style="font-size: 2rem;float: left;"></i><br>{{popUpMessage}}<a
        href="https://www.eyemaxx.ca/pages/contact-us"
        *ngIf="bookingSuccess!=true">https://www.eyemaxx.ca/pages/contact-us</a>
    </p>
  </div>
  <ng-template pTemplate="footer">
    <button pButton type="button" icon="pi pi-check" (click)="popUpNavigation('appointment')"
      label="Book another appointment" class="p-button-raised bookbutton"></button>
    <button pButton pRipple type="button" icon="pi pi-home" (click)="popUpNavigation('home')" label="Go to home"
      class="p-button-secondary homebutton">
    </button>
  </ng-template>
</p-dialog>

<p-dialog header=" " [(visible)]="CovidPopUpEnabled" [modal]="true" [style]="{width: '50vw'}" [baseZIndex]="10000"
  [draggable]="false" [resizable]="false" [position]="'top'" [closable]="true">
  <!-- <i class="pi pi-check-circle" style="font-size: 2rem;float: left;"></i> -->
  <p style="color: black;margin-left: 2%;">
    <i class="pi pi-check-circle" style="font-size: 2rem;float: left"></i><br>We offer appointments for all services
    including eye exams, glasses selection, frame repair/adjustment etc.

  </p><br>
  <p style="color: black;margin-left: 2%;">
    If your exam is covered by OHIP (children, senior, diabetic & other medical conditions), please call us to book and
    do not book online.
  </p>
  <ng-template pTemplate="footer">
    <!-- <button pButton type="button" icon="pi pi-check" (click)="popUpNavigation('appointment')"
            label="Book another appointment" class="p-button-raised bookbutton"></button>
        <button pButton pRipple type="button" icon="pi pi-home" (click)="popUpNavigation('home')" label="Go to home"
            class="p-button-secondary homebutton">
        </button> -->
  </ng-template>
</p-dialog>

<div class="progress-spinner" *ngIf="bookAppointmentSpinnerEnabled">
  <p-progressSpinner></p-progressSpinner>
</div>


<!-- Please note, all visits to Eyemaxx are by appointment only due to COVID-19 Restrictions -->


<!-- 4 th step related  -->

<!--
<p-dialog header=" " [showHeader]="true" [(visible)]="SMSPinDialogVisible" [modal]="true" [style]="{width: '50vw'}"
  [baseZIndex]="10000" [draggable]="true" [resizable]="false" [closable]="true" (onHide)="OnSMSPinCodeDialogHide()">
  <p style="text-align: center;">An SMS Pin code sent to your mobile,please enter the received Pin code</p>
  <form [formGroup]="SMSPinForm" (ngSubmit)="OnPinCodeFormSubmit($event)">
    <div class="flex-container-center-direction-column-pindcode-base">
      <div class="input-group pincode-item-base">
        <span class="input-group-addon">
          <i style="margin-top: 50%;" class="material-icons">sms</i>
        </span>
        <div class="form-group label-floating">
          <label class="control-label">Pin Code
          </label>
          <input type="text" class="form-control" formControlName="pin" tabindex="1" mask="0000">
        </div>
        <mat-error *ngIf="SMSPinFormSubmitted && smspf.pin.errors?.required"> Pin Code is required
        </mat-error><br>
        <mat-error *ngIf="SMSPinFormErrorVisible">{{SMSPinFormErrorText}} </mat-error>

      </div>
      <div>
        <button type="submit" class="btn btn-secondary pincodeform-submit-button-base"
          [disabled]="false">submit</button>


        <button class="btn btn-secondary pincodeform-submit-button-base" *ngIf="ResendCodeSeconds>120"
          style="background-color: white;color: black;" type="button" (click)="OnResendButtonClick()">resend
          SMS</button>
      </div>
      <p style="text-align: center;" *ngIf="SMSPinFormMessageVisible">{{SMSPinFormMessageText}}</p>
      <div *ngIf="SMSPinFormSpinnerVisible" style="display: flex;justify-content: center;">
        <p-progressSpinner [style]="{width: '50px', height: '50px'}" styleClass="custom-spinner" strokeWidth="5"
          fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>
      </div>
    </div>
  </form>
</p-dialog> -->
