import { HttpClient, HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpParams, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError, observable } from 'rxjs';
import { environment } from "src/environments/environment";
import { LoginBody } from '../models/login/login-body';
import { LoginResponse } from '../models/login/login-response';
import { catchError, map, retry, switchMap } from "rxjs/operators";
import { CreatePatientBody } from '../models/create-patient/create-patient-body';
import { BookAppointmentBody } from '../models/book-appointment/book-appointment-body';
import { FailedAppointmentResponse } from '../models/book-appointment/failed-appointment-response';
import { SuccessfullAppointmentResponse } from '../models/book-appointment/successfull-appointment-response';
import { UpdateBody } from '../models/update-patient-profile/update-body';
import { NavigationExtras, Router } from '@angular/router';
import { AddExceptionBody, AddTransactionBody, CreatePatientProfile, ForgotPasswordRequest, MWLoginBody, NewUserBookAppointment, PatientLoginRequest, ResetPasswordRequest, SendTokenPatient, TokenBeforeAppointment, ULPatient, UserLoginResponse, ValidateSMSPinRequest } from '../models/4thNewImplementation';
@Injectable({
  providedIn: 'root'
})
export class BookingModuleService implements HttpInterceptor {
  //intercept the http request to add Authorization headers to the request
  private Url: string = environment.ApiUrl;
  private MUrl: string = environment.MiddlewareURL;

  //api credentials
  accountsId: number = environment.accountsId;
  companyName: string = environment.companyName;
  private NonInterceptedRequests: string[] = [
    this.Url + '/login/patient',
    this.Url + '/Patient/ForgotPassword',
    this.Url + '/Patient/ValidateForgotPasswordPinCode',
    this.Url + '/Patient/ResetNewPassword',
    this.Url + '/Patient/sendToken',
    this.MUrl + '/login',
    this.MUrl + '/addTransaction',
    this.MUrl + '/addException',

    // this.Url + '/Appointment'

  ];
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.NonInterceptedRequests.includes(request.url)) {
      return next.handle(request);
    }
    else {
      let url: string = this.Url + '/login/doctors';
      if (request.url == url) {
        return next.handle(request).pipe(
          retry(1),
          catchError((error: HttpErrorResponse) => {
            let errorMessage: string = '';
            if (error.error instanceof ErrorEvent) {
              // client-side error
              errorMessage = `Error: ${error.error.message}`;
            } else {
              // server-side error
              errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;

              switch (error.status) {
                case 400:      //bad request
                  errorMessage += '   bad request';
                  break;
                case 401:      //login
                  errorMessage += '   unauthorized';
                  break;
                case 403:     //forbidden
                  errorMessage += '   forbidden';
                  break;
                case 404:     //not found
                  errorMessage += '   not found';
                  break;
                case 405:     //method nor allowed
                  errorMessage += '   method nor allowed';
                  break;
                case 500:     //internal server error
                  errorMessage += '   internal server error';
                  break;
                case 502:     //bad gateway
                  errorMessage += '   bad gateway';
                  break;
              }
            }
            let params: NavigationExtras = {
              queryParams: {
                "error": errorMessage,
              }
            };
            this.router.navigate(['/error',], params);
            return throwError(errorMessage);
          })
        );;
      }
      else {
        let loginBody: LoginBody = new LoginBody();

        let loginReponse: LoginResponse = new LoginResponse();
        const options = {
          headers: new HttpHeaders({
            'Accept': 'application/json,*/*',
            'Content-Type': 'application/json',
            'Authorization': 'Basic ' + btoa('carlo:1234')
          }),
        };
        return this.http.post(url, loginBody, options).pipe(switchMap((reponse) => {
          loginReponse.Initialize(reponse);
          request = request.clone({
            setHeaders: {
              'Content-Type': 'application/json',
              'Accept': 'application/json,*/*',
              'token': loginReponse.getToken
            }
          });
          return next.handle(request).pipe(
            retry(1),
            catchError((error: HttpErrorResponse) => {
              let errorMessage: string = '';
              if (error.error instanceof ErrorEvent) {
                // client-side error
                errorMessage = `Error: ${error.error.message}`;
              } else {
                // server-side error
                errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;

                switch (error.status) {
                  case 400:      //bad request
                    errorMessage += '   bad request';
                    break;
                  case 401:      //login
                    errorMessage += '   unauthorized';
                    break;
                  case 403:     //forbidden
                    errorMessage += '   forbidden';
                    break;
                  case 404:     //not found
                    errorMessage += '   not found';
                    break;
                  case 405:     //method nor allowed
                    errorMessage += '   method nor allowed';
                    break;
                  case 500:     //internal server error
                    errorMessage += '   internal server error';
                    break;
                  case 502:     //bad gateway
                    errorMessage += '   bad gateway';
                    break;
                }
              }
              let params: NavigationExtras = {
                queryParams: {
                  "error": errorMessage,
                }
              };
              this.router.navigate(['/error',], params);
              return throwError(errorMessage);
            })
          );
        }));
      }
    }
  }


  //api credentials

  //using http client module to make api calls
  constructor(private http: HttpClient, private router: Router) { }
  //login method to generate api key by staff credentials
  generateAPIKey(body: LoginBody): Observable<LoginResponse> {
    const options = {
      headers: new HttpHeaders({
        'Accept': 'application/json',
        'Authorization': 'Basic ' + btoa('carlo:1234')
      }),

    }
    return this.http.post<LoginResponse>(this.Url + '/login/doctors', body, options);
  }
  //create new patient
  createPatient(body: CreatePatientBody) {
    return this.http.post(this.Url + '/Patient', body);
  }

  //get patient list related to a patient email
  getPatientList(param: string, paramVal: string) {
    const params = new HttpParams().set(param, paramVal).set('match', 'all');
    return this.http.get(this.Url + '/Patient/list', { params });
  }


  //get patient info by his ID
  getPatientById(id: number) {
    return this.http.get(this.Url + '/Patient/' + id);
  }

  //get stores doctors and appointments
  getStoresTypesDoctors(accountsId: number, companyName: string) {
    const params = new HttpParams()
      .set('accountsId', accountsId.toString())
      .set('companyName', companyName);
    return this.http.get(this.Url + '/Appointment', { params });
  }


  //get available appointments slots based on query strings parameters
  getAvailableAppointmentSluts(accountsId: string, companyName: string,
    appointmentTypeId: string, doctorId?: string) {
    let params = new HttpParams()
      .set('accountsId', accountsId)
      .set('companyName', companyName)
      .set('storeId', '1')
      .set('appointmentTypeId', appointmentTypeId)
      .set('storeTimeZone', 'Canada/Eastern')
      .set('locale', 'en');


    if (doctorId != undefined) {
      params = new HttpParams()
        .set('accountsId', accountsId)
        .set('companyName', companyName)
        .set('storeId', '1')
        .set('appointmentTypeId', appointmentTypeId)
        .set('storeTimeZone', 'Canada/Eastern')
        .set('locale', 'en')
        .set('doctorId', doctorId);
    }
    return this.http.get(this.Url + '/Appointment/list', { params });
  }


  //book new appointment
  bookNewAppointment(body: any): Observable<any> {
    return this.http.post<any>(this.Url + '/Appointment', JSON.stringify(body));
  }

  // bookNewAppointment(body: BookAppointmentBody) {
  //   console.log(JSON.stringify(body));
  //   return this.http.post(this.Url + '/Appointment', JSON.stringify(body));
  // }

  //get specific patient appointments
  getPatientAppointments(id: number) {
    return this.http.get(this.Url + '/Appointment/patient/' + id);
  }

  //get patient profile
  getPatientProfile(id: number) {
    return this.http.get(this.Url + '/Patient/' + id);
  }

  //update patient profile
  updatePatientProfile(body: UpdateBody) {
    return this.http.put(this.Url + '/Patient', body);
  }

  // error handler
  handleError(error: HttpErrorResponse) {
    let errorMessage: string = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;

      switch (error.status) {
        case 400:      //bad request
          errorMessage += '   bad request';
          break;
        case 401:      //login
          errorMessage += '   unauthorized';
          break;
        case 403:     //forbidden
          errorMessage += '   forbidden';
          break;
        case 404:     //not found
          errorMessage += '   not found';
          break;
        case 405:     //method nor allowed
          errorMessage += '   method nor allowed';
          break;
        case 500:     //internal server error
          errorMessage += '   internal server error';
          break;
        case 502:     //bad gateway
          errorMessage += '   bad gateway';
          break;
      }
    }
    return throwError(errorMessage);
  }

  //4Th step new implementation
  CellPhoneMatch(_cell: string): Observable<boolean> {
    const params = new HttpParams().set('mobile', _cell).set('match', 'all');
    return this.http.get<boolean>(this.Url + '/Patient/list', { params }).pipe(
      map((data: any) => {
        if (data) {
          if (data['patientList'][0]) {
            return true;
          }
          return false;
        }
        return false;
      })
    );
  }


  ForgotPassword(_body: ForgotPasswordRequest): Observable<boolean> {
    return this.http.post<boolean>(this.Url + '/Patient/ForgotPassword', _body).pipe(
      map((data: any) => {
        if (data) {
          if (data['smsSent'] == true) {
            return true
          }
          return false;
        }
        return false;
      }), catchError((err, caught) => {
        return of(false);
      })
    );
  }
  UserLogin(_body: PatientLoginRequest): Observable<UserLoginResponse | number> {
    return this.http.post<any>(this.Url + '/login/patient', _body, { observe: 'response' }).pipe(
      map((data: any) => {

        if (data.body.message) {
          let errorMessage: string = data.body.message;
          return errorMessage === 'InvalidCredentials' ? 1 : 2;
        } else if (data.body.patient.length == 0) {
          return 3;
        }
        else {
          let result: UserLoginResponse = new UserLoginResponse();
          let pl: ULPatient[] = [];
          let tempData: any = data.body.patient;
          tempData.forEach((element: any) => {
            let tempPatient: ULPatient = new ULPatient();
            tempPatient = Object.assign(tempPatient, element);
            pl.push(tempPatient);
          });
          result.Token = data.headers.get('Token');
          result.PatientList = pl;
          return result;
        }
      })
    );
  }

  ValidateSMSPinCode(_body: ValidateSMSPinRequest): Observable<boolean> {
    return this.http.post<boolean>(this.Url + '/Patient/ValidateForgotPasswordPinCode', _body).pipe(
      map((data: any) => {
        if (data) {
          if (data['message'] != 'error') {
            return true;
          }
          return false;
        }
        return false;
      })
    );
  }


  ResetPassword(_body: ResetPasswordRequest): Observable<boolean> {
    return this.http.post(this.Url + '/Patient/ResetNewPassword', _body).pipe(
      map((data: any) => {
        if (data) {
          if (data['message'] == 'success') {
            return true;
          }
          return false;
        }
        return false;
      })
    );
  }



  NewUserBookAppointment(_body: NewUserBookAppointment): Observable<string> {
    return this.http.post(this.Url + '/Appointment', _body).pipe(
      map((data: any) => {
        if (data) {
          if (data.smsversion) {
            return 'true';
          }
          else {
            if (data.smsPinCodeInvalid) {
              return 'inv'
            }
            else
              return 'false';
          }
        }
        return 'false';
      })
    );
  }

  // {"smsPinCodeInvalid":true,"errors":["smsPinCodeInvalid"]}


  SendTokenBeforeAppointment(_body: TokenBeforeAppointment): Observable<boolean | SendTokenPatient[]> {
    return this.http.post(this.Url + '/Patient/sendToken', _body).pipe(
      map((data: any) => {
        if (data) {
          if (data.smsSent == true)
            return true;
          else if (data.patient) {
            let pl: any[] = data.patient;
            let result: SendTokenPatient[] = [];
            pl.forEach(patient => {
              let p: SendTokenPatient = new SendTokenPatient();
              p.firstName = patient.firstName;
              p.lastName = patient.lastName;
              p.dob = patient.dob ? new Date(Date.parse(patient.dob)) : null;
              p.cell = patient.cell;
              p.email = patient.email;
              p.patientId = patient.patientId;
              result.push(p);
            });
            return result;
          }
          else if (data.patientloginExist)
            return false;
          else
            return false;
        }
        return false;
      })
    )
  }

  CreatePatientProfile = (_body: CreatePatientProfile): Observable<boolean> => {
    return this.http.post(this.Url + '/PatientProfile', _body).pipe(map((data: any) => {
      if (data.smsPinCodeInvalid)
        return false;
      else if (data.patient) {
        return true;
      }
      else
        return false;
    }));
  }

  MiddlewareLogin(): Observable<string> {
    let _body: MWLoginBody = new MWLoginBody();
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      }),
    }
    return this.http.post(this.MUrl + '/login', _body, options).pipe(
      map((data: any) => {
        return data.access_token;
      })
    )
  }

  MiddlewareAddTransaction(_body: AddTransactionBody): Observable<AddTransactionBody> {
    return this.http.post<AddTransactionBody>(this.MUrl + '/addTransaction', _body);
  }

  MiddlewareAddException(_body: AddExceptionBody): Observable<AddExceptionBody> {
    return this.http.post<AddExceptionBody>(this.MUrl + '/addException', _body);
  }

}



//patientloginExist
