import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BookAppointmentBody } from '../../models/book-appointment/book-appointment-body';
import { FailedAppointmentResponse } from '../../models/book-appointment/failed-appointment-response';
import { Step } from '../../models/Step/step';
import { AppointmentConfirmationData } from '../../models/Steps-Data/appointment-confirmation-data';
import { AppointmentSlotData } from '../../models/Steps-Data/appointment-slot-data';
import { AppointmentSummaryData } from '../../models/Steps-Data/appointment-summary-data';
import { AppointmentTypeData } from '../../models/Steps-Data/appointment-type-data';
import { BookingModuleService } from '../../services/booking-module-service.service';
import { StepsManagementService } from '../../services/steps-management.service';
import { MessageService } from 'primeng/api';
import { PrimeNGConfig } from 'primeng/api';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Patient } from '../../models/create-patient/patient';
import { Emaildata } from '../../models/email-api-data/emaildata';
import { DatePipe } from '@angular/common';
import { AppointmentType } from '../../models/get-stores-types-doctors/appointment-type';
import { Doctor } from '../../models/get-stores-types-doctors/doctor';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NewUserBookAppointment, TokenBeforeAppointment } from '../../models/4thNewImplementation';
import { environment } from 'src/environments/environment';
import { Observable, Subscription, timer } from 'rxjs';
import { map, share, take } from 'rxjs/operators';



@Component({
  selector: 'app-base-content',
  templateUrl: './base-content.component.html',
  styleUrls: ['./base-content.component.css'],
  providers: [MessageService, DatePipe]
})
export class BaseContentComponent implements OnInit {
  showBookAppointmentPopUp: boolean = false;
  popUpMessage: string = '';
  popUpToastMessage: string = '';
  popIconType: string = '';
  bookingSuccess: boolean = false;
  bookAppointmentSpinnerEnabled: boolean = false;
  windowWidth: number = 0;
  CovidPopUpEnabled: boolean = false;


  //4th steps related attr
  SMSPinDialogVisible: boolean = false;
  SMSPinForm!: FormGroup;
  SMSPinFormSubmitted: boolean = false;
  SMSPinFormSpinnerVisible: boolean = false;
  SMSPinFormErrorVisible: boolean = false;
  SMSPinSubmitButtonsDisabled: boolean = false;
  SMSPinFormErrorText: string = ' ';

  SMSPinFormMessageVisible: boolean = false;
  SMSPinFormMessageText: string = '';
  ResendCodeObservable: Observable<number> = new Observable<number>();
  ResendCodeSeconds: number = 0;
  ObservableSubscribtion!: Subscription;

  SubscribeToObservable() {
    this.ResendCodeSeconds = 0;
    this.ObservableSubscribtion = this.ResendCodeObservable.subscribe(x => {
      this.ResendCodeSeconds = x;
    });
  }
  UnsubscribeToObservable() {
    this.ResendCodeSeconds = 0;
    this.ObservableSubscribtion.unsubscribe();
  }



  get smspf() {
    return this.SMSPinForm.controls;
  }

  // OnPinCodeFormSubmit(e: any) {
  //   this.SMSPinFormSubmitted = true;
  //   if (this.SMSPinForm.invalid) {
  //     e.preventDefault();
  //     return;
  //   }
  //   this.SMSPinForm.disable();
  //   this.bookAppointmentSpinnerEnabled = true;
  //   this.SMSPinFormErrorVisible = false;
  //   this.SMSPinFormErrorText = '';
  //   this.SMSPinFormMessageText = '';
  //   this.SMSPinFormErrorVisible = false;
  //   let appointmentTypeData: AppointmentTypeData = <AppointmentTypeData>this.steps.stepsData.filter(x => x.order == 2)[0];
  //   let appointmentSlotData: AppointmentSlotData = <AppointmentSlotData>this.steps.stepsData.filter(x => x.order == 3)[0];
  //   let appointmentConfirmationData: AppointmentConfirmationData = <AppointmentConfirmationData>this.steps.stepsData.filter(x => x.order == 4)[0];
  //   let appointmentSummaryData: AppointmentSummaryData = <AppointmentSummaryData>this.steps.stepsData.filter(x => x.order == 5)[0];
  //   //booking body
  //   let _body: NewUserBookAppointment = new NewUserBookAppointment();
  //   _body = Object.assign(_body, appointmentConfirmationData.NewPatientInfo);
  //   _body.appointmentTypeId = appointmentTypeData.ExamType.id;
  //   _body.storeId = environment.storeId;
  //   _body.slotId = appointmentSlotData.SelectedTimeSlot.id;
  //   //_body.patientId = +appointmentConfirmationData.SelectedUser.id.split('-')[1];
  //   _body.doctorId = appointmentSlotData.SelectedTimeSlot.doctorId.toString();
  //   let selectedDoctor: Doctor = new Doctor();
  //   selectedDoctor = appointmentTypeData.DoctorStoreTypeData.Doctors.filter(x => x.id == appointmentSlotData.SelectedTimeSlot.doctorId)[0];
  //   _body.accountsId = environment.accountsId;
  //   _body.smsPinCode = this.SMSPinForm.get('pin')?.value;

  //   this.SMSPinFormSpinnerVisible = true;
  //   this.serv.NewUserBookAppointment(_body).subscribe(result => {
  //     if (result == 'true') {
  //       this.UnsubscribeToObservable();
  //       if (appointmentSummaryData.OpticianAppointment) {
  //         //filling email data
  //         //filling patient data
  //         let data: Emaildata = new Emaildata();
  //         data.firstName = appointmentConfirmationData.NewPatientInfo.firstName;
  //         data.lastName = appointmentConfirmationData.NewPatientInfo.lastName;
  //         data.streetName = appointmentConfirmationData.NewPatientInfo.streetNumber;
  //         data.streetNumber = appointmentConfirmationData.NewPatientInfo.streetNumber;
  //         data.city = appointmentConfirmationData.NewPatientInfo.city;
  //         data.province = appointmentConfirmationData.NewPatientInfo.province;
  //         data.postalCode = appointmentConfirmationData.NewPatientInfo.postalCode;
  //         data.dateOfBirth = appointmentConfirmationData.NewPatientInfo.dateOfBirth;
  //         data.cell = appointmentConfirmationData.NewPatientInfo.cell;
  //         data.email = appointmentConfirmationData.NewPatientInfo.email;
  //         data.medicalCardExp = appointmentConfirmationData.NewPatientInfo.medicalCardExp;
  //         data.medicalCard = appointmentConfirmationData.NewPatientInfo.medicalCard;
  //         data.appointmentType = appointmentTypeData.ExamType.name;
  //         //filling appointment data
  //         let appointmentDate: any = this.dp.transform(appointmentSlotData.SelectedDate, 'yyyy-MM-dd');
  //         appointmentDate += ' ' + appointmentSlotData.SelectedTimeSlot.start + '-' + appointmentSlotData.SelectedTimeSlot.end;
  //         data.appointmentDate = appointmentDate;
  //         data.optimtrist = selectedDoctor.firstName + ' ' + selectedDoctor.lastName;

  //         // call email api
  //         this.sendEmail(data).subscribe(x => {
  //         });
  //       }
  //       this.SMSPinDialogVisible = false;
  //       this.bookingSuccess = true;
  //       if (appointmentSummaryData.OpticianAppointment == true) {
  //         this.popUpMessage = ' Thank you! Your eye exam has been booked and you will receive a SMS confirmation shortly. We have received your request to shop for glasses, you will hear from us soon to confirm this appointment.';
  //       } else {
  //         this.popUpMessage = ' Thank you for booking with Eyemaxx, you will receive a SMS confirmation shortly.';
  //       }
  //       this.popUpToastMessage = ' Your appoitment successfully booked !';
  //       this.popIconType = 'pi pi-check-circle';
  //       this.showBookAppointmentPopUp = true;
  //       this.messageService.add({ severity: 'success', summary: 'Succeed', detail: this.popUpToastMessage });

  //     }
  //     else if (result == 'inv') {
  //       this.UnsubscribeToObservable();
  //       this.bookingSuccess = false;
  //       this.messageService.add({ severity: 'error', summary: 'Invalid PIN code. Make sure you entered the correct code or send another one to your phone.', detail: this.popUpToastMessage });
  //       this.SMSPinFormErrorText = 'Invalid SMS Pin Code, you can resend SMS Pin code after 2 minutes.'
  //       this.SMSPinFormErrorVisible = true;
  //       this.SMSPinFormSpinnerVisible = false;
  //       this.SubscribeToObservable();

  //     }
  //     else {
  //       this.bookingSuccess = false;
  //       this.popUpMessage = ' Sorry, this user already has an appointment booked. To book a second appointment, please contact us to book and then a button that leads them to our contact page ';
  //       this.popUpToastMessage = ' Sorry you already booked an appointment';
  //       this.popIconType = 'pi pi-info-circle';
  //       this.showBookAppointmentPopUp = true;
  //       this.messageService.add({ severity: 'error', summary: 'Failed', detail: this.popUpToastMessage });
  //       this.SMSPinFormSpinnerVisible = false;
  //       // this.SMSPinDialogVisible = false;
  //     }
  //     this.bookAppointmentSpinnerEnabled = false;
  //     this.SMSPinForm.enable();
  //     // this.SMSPinDialogVisible = false;
  //   });

  // }

  // OnResendButtonClick() {
  //   let appointmentConfirmationData: AppointmentConfirmationData = <AppointmentConfirmationData>this.steps.stepsData.filter(x => x.order == 4)[0];
  //   this.SMSPinFormSpinnerVisible = true;
  //   this.SMSPinFormErrorVisible = false;
  //   this.SMSPinFormErrorText = '';
  //   this.SMSPinFormMessageText = '';
  //   this.SMSPinFormErrorVisible = false;
  //   this.UnsubscribeToObservable();
  //   let tokenBody: TokenBeforeAppointment = new TokenBeforeAppointment();
  //   tokenBody = Object.assign(tokenBody, appointmentConfirmationData.TokenBody);
  //   tokenBody.email = '';
  //   this.serv.SendTokenBeforeAppointment(tokenBody).subscribe(result => {
  //     if (result) {
  //       this.SMSPinFormMessageText = 'An SMS Pin code has been sent to you mobile,please fill the received SMS PIN and press submit to book your appointment.';
  //     } else {
  //       this.SMSPinFormMessageText = 'Error while sending the SMS PIN.';
  //     }
  //     this.SMSPinFormMessageVisible = true;
  //     this.SMSPinFormErrorVisible = false;
  //     this.SMSPinFormSpinnerVisible = false;
  //     this.SMSPinSubmitButtonsDisabled = false;
  //     this.SubscribeToObservable();
  //   });
  // }




  constructor(private steps: StepsManagementService,
    private router: Router, private route: ActivatedRoute,
    private serv: BookingModuleService,
    private messageService: MessageService,
    private primengConfig: PrimeNGConfig,
    private http: HttpClient,
    private dp: DatePipe,
    private fb: FormBuilder) { }


  ngOnInit(): void {

    this.primengConfig.ripple = true;
    this.serv.getStoresTypesDoctors(this.serv.accountsId, this.serv.companyName).subscribe(t => {
      this.steps.ExamTypesPreFetch.Initialize(t);
      this.steps.InitiailzeExamTypes(this.steps.preDefinedExamTypes, this.steps.ExamTypesPreFetch.AppointmentTypes);
    });

    // this.CovidPopUpEnabled = true;
    this.SMSPinForm = this.fb.group({
      pin: ['', Validators.compose([Validators.required])]
    });

    this.ResendCodeObservable = timer(1000, 1000).pipe(
      map((x: number) => {
        return x;
      })
    );

    this.serv.MiddlewareLogin().subscribe(x => {
    });
  }

  sendEmail(data: Emaildata) {

    const options = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET, POST, OPTIONS',
        'Access-Control-Allow-Headers': 'Origin, Content-Type, Accept, Authorization, X-Request-With',
        'Access-Control-Allow-Credentials': 'true',
        'Accept': '*/*',
        'Content-Type': 'application/json'
      }),
    };
    return this.http.post('https://appointment-eyemaxx.ca/api/contact3.php', JSON.stringify(data), options);
  }

  stepsEnabled(_order: number) {
    let enabled: boolean = this.steps.Steps.filter(x => x.order == _order)[0].enabled;
    return enabled;
  }

  currentStepStatus(): Step {
    return this.steps.currentStep;
  }

  navigate(id: number) {
    let s: string = this.steps.Steps.filter(x => x.order == id)[0].route;
    this.router.navigate([s], { relativeTo: this.route });
  }

  CheckAllStepsValidity(): boolean {
    return this.steps.Steps.map(x => x.validated).every(y => {
      return y == true;
    });
  }

  BookAppointment() {
    // let appointmentConfirmationData: AppointmentConfirmationData = <AppointmentConfirmationData>this.steps.stepsData.filter(x => x.order == 4)[0];
    // if (appointmentConfirmationData.NewOrExisting == 'NEW') {
    //   this.SMSPinDialogVisible = true;
    //   let tokenBody: TokenBeforeAppointment = new TokenBeforeAppointment();
    //   tokenBody = Object.assign(tokenBody, appointmentConfirmationData.TokenBody);
    //   tokenBody.email = '';
    //   this.serv.SendTokenBeforeAppointment(tokenBody).subscribe(result => {
    //     this.SubscribeToObservable();
    //   });
    // }

    this.bookAppointmentSpinnerEnabled = true;
    if (this.currentStepStatus().order == 5) {
      let appointmentTypeData: AppointmentTypeData = <AppointmentTypeData>this.steps.stepsData.filter(x => x.order == 2)[0];
      let appointmentSlotData: AppointmentSlotData = <AppointmentSlotData>this.steps.stepsData.filter(x => x.order == 3)[0];
      let appointmentConfirmationData: AppointmentConfirmationData = <AppointmentConfirmationData>this.steps.stepsData.filter(x => x.order == 4)[0];
      let appointmentSummaryData: AppointmentSummaryData = <AppointmentSummaryData>this.steps.stepsData.filter(x => x.order == 5)[0];
      let body: BookAppointmentBody = new BookAppointmentBody();

      body.appointmentTypeId = appointmentTypeData.ExamType.id;
      body.storeId = 1;
      body.slotId = appointmentSlotData.SelectedTimeSlot.id;
      body.patientId = +appointmentConfirmationData.SelectedUser.id.split('-')[1];
      body.doctorId = appointmentSlotData.SelectedTimeSlot.doctorId.toString();
      let selectedDoctor: Doctor = new Doctor();
      selectedDoctor = appointmentTypeData.DoctorStoreTypeData.Doctors.filter(x => x.id == appointmentSlotData.SelectedTimeSlot.doctorId)[0];
      console.log('patient', appointmentConfirmationData);
      console.log('summary', appointmentSummaryData);


      this.serv.bookNewAppointment(body).subscribe(x => {
        if (!x.hasOwnProperty('smsversion')) {
          this.bookingSuccess = false;
          this.popUpMessage = ' Sorry, this user already has an appointment booked. To book a second appointment, please contact us to book and then a button that leads them to our contact page ';
          this.popUpToastMessage = ' Sorry you already booked an appointment';
          this.popIconType = 'pi pi-info-circle';
          this.showBookAppointmentPopUp = true;
          this.messageService.add({ severity: 'error', summary: 'Failed', detail: this.popUpToastMessage });
        }
        else {
          if (appointmentSummaryData.OpticianAppointment || appointmentSummaryData.WheelChairRequest) {
            let data: Emaildata = new Emaildata();
            data.firstName = appointmentConfirmationData.SelectedUser.firstName;
            data.lastName = appointmentConfirmationData.SelectedUser.lastName;
            data.streetName = appointmentConfirmationData.SelectedUser.streetNumber;
            data.streetNumber = appointmentConfirmationData.SelectedUser.streetNumber;
            data.city = appointmentConfirmationData.SelectedUser.city;
            data.province = appointmentConfirmationData.SelectedUser.province;
            data.postalCode = appointmentConfirmationData.SelectedUser.postalCode;
            data.dateOfBirth = appointmentConfirmationData.SelectedUser.dateOfBirth;
            data.cell = appointmentConfirmationData.SelectedUser.cell;
            data.email = appointmentConfirmationData.SelectedUser.email;
            data.medicalCardExp = appointmentConfirmationData.SelectedUser.medicalCardExp;
            data.medicalCard = appointmentConfirmationData.SelectedUser.medicalCard;
            data.appointmentType = appointmentTypeData.ExamType.name;
            //filling appointment data
            let appointmentDate: any = this.dp.transform(appointmentSlotData.SelectedDate, 'yyyy-MM-dd');
            appointmentDate += ' ' + appointmentSlotData.SelectedTimeSlot.start + '-' + appointmentSlotData.SelectedTimeSlot.end;
            data.appointmentDate = appointmentDate;
            data.optimtrist = selectedDoctor.firstName + ' ' + selectedDoctor.lastName;
            data.opticianAppointment = appointmentSummaryData.OpticianAppointment == true ? 'Yes' : 'No';
            data.isWheel = appointmentSummaryData.WheelChairRequest == true ? 'Yes' : 'No';
            data.medicalCardVersion = appointmentConfirmationData.SelectedUser.medicalCardVersion;
            this.sendEmail(data).subscribe(x => {
            });
          }
          this.bookingSuccess = true;
          if (appointmentSummaryData.OpticianAppointment == true) {
            this.popUpMessage = ' Thank you! Your eye exam has been booked and you will receive a SMS confirmation shortly. We have received your request to shop for glasses, you will hear from us soon to confirm this appointment.';
          } else {
            this.popUpMessage = ' Thank you for booking with Eyemaxx, you will receive a SMS confirmation shortly.';
          }
          this.popUpToastMessage = ' Your appoitment successfully booked !';
          this.popIconType = 'pi pi-check-circle';
          this.showBookAppointmentPopUp = true;
          this.messageService.add({ severity: 'success', summary: 'Succeed', detail: this.popUpToastMessage });
        }
        this.bookAppointmentSpinnerEnabled = false;
      });
    }


  }

  OnSMSPinCodeDialogHide() {
    this.UnsubscribeToObservable();
    this.SMSPinFormSubmitted = false;
    this.SMSPinFormErrorVisible = false;
    this.SMSPinForm.reset();
    this.SMSPinFormMessageVisible = false;
  }


  popUpNavigation(route: string) {
    if (route == 'home') {
      window.location.href = "https://www.eyemaxx.ca";
    }
    else {
      this.steps.clearSteps(1);
      window.location.reload();
    }
    this.showBookAppointmentPopUp = false;
  }

  onActivate(event: any) {
    window.scrollTo({
      top: 200,
      behavior: 'smooth'
    });
    // let scrollToTop = window.setInterval(() => {
    //   let pos = window.pageYOffset;
    //   if (pos > 0) {
    //     window.scrollTo(0, pos-2); // how far to scroll on each step
    //   } else {
    //     window.clearInterval(scrollToTop);
    //   }
    // }, 20);
  }

}

