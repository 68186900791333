<div class="tab-pane" id="confirm">
  <!-- user scenario component -->

  <div class="row" *ngIf="getPartInfo('UserScenario')?.Enabled">
    <h4 class="info-text"> If this is your first time logging in, please "Create An Account".</h4>
    <div style="display: flex;justify-content: center;">
      <div>
        <button type="button" class="btn user-matching-submit-button" (click)="redirectTo('CreateNewPatient')">Create An
          Account
        </button>
      </div>
    </div>
    <div style="display: flex;justify-content: center;">
      <div>
        <button type="button" class="btn" style="text-align: center;width: 170px;background-color: white;color: black;"
          (click)="redirectTo('Login')">Login</button>
      </div>
    </div>
  </div>

  <div class="row">
    <!-- login form -->
    <div class="row" *ngIf="getPartInfo('Login')?.Enabled">
      <form [formGroup]="loginForm" (ngSubmit)="onLoginFormSubmit()">
        <div class="flex-container-center-direction-column-user-login">

          <div class="input-group login-item col-lg-offset-2">

            <span class="input-group-addon phone-span">
              <i class="material-icons phone-icon">phone_iphone</i>
            </span>
            <div id="phone_div" class="form-group label-floating col-lg-6">
              <label class="control-label addpatientlabel">Cell Phone Number
              </label>

              <input type="text" class="form-control login-form-input" formControlName="loginEmail" tabindex="1"
                mask="(000) 000-0000" style="border-bottom: none;">
            </div>
          </div>
          <div class="row">
            <mat-error id="mat-error-1" *ngIf="loginFormSubmitted && loginFormControls.loginEmail.errors?.required">Cell
              Phone Number
              is required
            </mat-error>
            <mat-error id="mat-error-1" *ngIf="loginFormSubmitted && loginFormControls.loginEmail.errors?.minlength">
              Invalid
              Cell
              Phone Number

            </mat-error>
          </div>
          <div class=" input-group login-item col-lg-offset-2" style="margin-top: 2%;">
            <span class="input-group-addon password-span">
              <i id="lock-icon" class="material-icons password-icon">lock</i>
            </span>
            <div id="password_div" class="form-group label-floating col-lg-6">
              <label class="control-label addpatientlabel">Password
              </label>
              <input type="password" class="form-control login-form-input" formControlName="password" tabindex="2">
            </div>
          </div>
          <div class="row">
            <mat-error *ngIf="loginFormSubmitted && loginFormControls.password.errors?.required">Password is required
            </mat-error>
          </div>
          <div style="padding-top: 2%;
          padding-bottom: 2%;" *ngIf="getPartErrors('Login',1)">
            <p class="text-center error-msg" style="color: red;">Invalid username or password.</p>
          </div>
          <div style="padding-top: 2%;
          padding-bottom: 2%;" *ngIf="getPartErrors('Login',2)">
            <p class="text-center error-msg" style="color: red;">Your account has been temporarily locked,please try
              again after 15 minutes.</p>
          </div>
          <div style="padding-top: 2%;
          padding-bottom: 2%;" *ngIf="getPartErrors('Login',3)">
            <p class="text-center error-msg" style="color: red;">This is your first time logging in, please create your
              account by clicking the icon below.</p>
          </div>
          <div style="margin-top: 2px;" [class.disabled-div]="multiplePressing" (click)="onForgotPasswordLinkClick()">
            <a style="cursor: pointer;" type="button">Forgot your password? </a>
          </div>
          <div>
            <button type="submit" class="btn btn-secondary login-submit-button"
              [disabled]="multiplePressing">submit</button>
          </div>

          <div style="display: flex;justify-content: center;">
            <button class="btn" type="button" (click)="redirectTo('CreateNewPatient')" [disabled]="multiplePressing">
              CREATE ACCOUNT
            </button>
          </div>
        </div>
      </form>
    </div>
    <!-- <h1 *ngIf="getPartInfo('PatientList')?.Enabled">hello patient list</h1> -->

    <!-- patient list -->
    <div class="row" *ngIf="getPartInfo('PatientList')?.Enabled && patientList.patientList.length>0">
      <div class="patientlist-flex-container" [class.disabled-div]="multiplePressing">
        <div class="row">
          <h4 class="info-text">Please select an account or create a new profile</h4>
        </div>

        <div style="height: 185px;" class="col-lg-4 patient-div" *ngFor="let patient of patientList.patientList"
          (click)="onPatientSelect(patient.id)">
          <div class="choice" data-toggle="wizard-radio" rel="tooltip" [class.active]="selectedPatientId === patient.id"
            title="{{patient.firstName+' '+patient.lastName }}">
            <input type="radio" name="job" value="Design">
            <div class="icon">
              <i class="material-icons">person</i>
            </div>
            <h6>{{patient.firstName+ ' ' + patient.lastName}}</h6>
            <button style="font-size: 8pt;" pButton pRipple type="button" icon="pi pi-user-edit"
              *ngIf="(selectedPatientId!=-1)&&(selectedPatientId === patient.id)"
              (click)="onUpdatePatientClickButton(patient.id)"
              class="p-button-rounded p-button-outlined user-edit-button">
            </button>
          </div>
        </div>
        <div style="height: 200px;" class=" col-lg-4" (click)="onPatientSelect(-1)">
          <div class="choice" data-toggle="wizard-radio" rel="tooltip" title="New User">
            <input type="radio" name="job" value="Code">
            <div class="icon">
              <i class="material-icons">person_add</i>
            </div>
            <h6>New Patient</h6>
          </div>
        </div>

      </div>
    </div>


    <!-- update guest patient form -->
    <h5 class="text-center" *ngIf="getPartInfo('UpdateGuestPatient')?.Enabled">Please fill out the required information
      below
      and click on
      submit.
    </h5>
    <div class="row" *ngIf="getPartInfo('UpdateGuestPatient')?.Enabled">
      <form [formGroup]="updateGuestPatientForm" (ngSubmit)="onUpdateGuestPatientFormSubmit()">
        <div class="col-lg-12" id="optician-contact-form">
          <div class="col-lg-4 col-md-4 col-sm-4">
            <div class="input-group">
              <span class="input-group-addon new-user-confirm-password-span">
                <img src="../../../../assets/img/First Name.png" class="firstname-icon">
              </span>
              <div class="form-group label-floating">
                <label class="control-label addpatientlabel">First Name
                  <small>(required)</small></label>
                <input name="firstname" type="text" class="form-control" formControlName="firstName" tabindex="1">
              </div>

            </div>
            <div class="row">
              <mat-error class="text-center"
                *ngIf="updateGuestPatientFormSubmitted && updateGuestPatientFormControls.firstName.errors?.required">
                First
                Name is required
              </mat-error>
              <mat-error class="text-center"
                *ngIf="updateGuestPatientFormSubmitted && updateGuestPatientFormControls.firstName.errors?.pattern">
                Invalid
                First
                Name
              </mat-error>
            </div>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-4">
            <div class="input-group">
              <span class="input-group-addon new-user-confirm-password-span">
                <img src="../../../../assets/img/Last Name.png" class="lastname-icon">
              </span>
              <div class="form-group label-floating">
                <label class="control-label addpatientlabel">Last Name
                  <small>(required)</small></label>
                <input name="lastName" type="text" class="form-control" formControlName="lastName" tabindex="2">
              </div>

            </div>
            <div class="row">
              <mat-error class="text-center"
                *ngIf="updateGuestPatientFormSubmitted && updateGuestPatientFormControls.lastName.errors?.required">
                Last
                Name is required
              </mat-error>
              <mat-error class="text-center"
                *ngIf="updateGuestPatientFormSubmitted && updateGuestPatientFormControls.lastName.errors?.pattern">
                Invalid
                Last
                Name
              </mat-error>
            </div>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-4">
            <div class="input-group">
              <span class="input-group-addon new-user-confirm-password-span">
                <img src="../../../../assets/icons/Icons/calender.png" width="32" class="calender-icon">
              </span>
              <div class="form-group label-floating">
                <label class="control-label addpatientlabel">Date of Birth
                  <small>(required)</small></label>
                <input name="dateOfBirth" type="date" class="form-control" formControlName="dateOfBirth" tabindex="3"
                  max="3000-01-01">
              </div>

            </div>
            <div class="row">
              <mat-error class="text-center"
                *ngIf="updateGuestPatientFormSubmitted && updateGuestPatientFormControls.dateOfBirth.errors?.required">
                Date
                of Birth is
                required</mat-error>

            </div>
          </div>
        </div>
        <div class="col-lg-12">
          <div class="col-lg-4 col-md-4 col-sm-4">
            <div class="input-group">
              <span class="input-group-addon new-user-confirm-password-span">
                <img src="../../../../assets/img/Email.png" class="email-icon">
              </span>
              <div class="form-group label-floating">
                <label class="control-label addpatientlabel">Email
                  <small>(required)</small></label>
                <input name="email" type="text" class="form-control" formControlName="email" tabindex="4">
              </div>


            </div>
            <div class="row">
              <mat-error class="text-center"
                *ngIf="updateGuestPatientFormSubmitted && updateGuestPatientFormControls.email.errors?.email">Invalid
                Email
                format
              </mat-error>
            </div>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-4">
            <div class="input-group">
              <span class="input-group-addon new-user-confirm-password-span">
                <img src="../../../../assets/img/Cell Phone.png" class="cellphone-icon">
              </span>
              <div class="form-group label-floating">
                <label class="control-label addpatientlabel">Cell Phone
                  <small>(required)</small></label>
                <input name="cell" type="text" class="form-control" formControlName="cell" tabindex="11"
                  mask="(000) 000-0000" placeholder="(xxx) xxx-xxxx" style="border-bottom: none;">
              </div>

            </div>

            <div class="row">
              <mat-error class="text-center"
                *ngIf="updateGuestPatientFormSubmitted && updateGuestPatientFormControls.cell.errors?.required">Cell
                Phone
                is required
              </mat-error>
              <mat-error class="text-center"
                *ngIf="updateGuestPatientFormSubmitted && updateGuestPatientFormControls.cell.errors?.pattern">Cell
                Phone
                format should be
                xxx-xxx-xxxx
              </mat-error>
            </div>
          </div>


          <div class="col-lg-4 col-md-4 col-sm-4">
            <div class="input-group">
              <span class="input-group-addon new-user-confirm-password-span">
                <img src="../../../../assets/img/Province.png" class="province-icon">
              </span>
              <div class="form-group label-floating">
                <label class="control-label addpatientlabel">Province
                  <small>(required)</small></label>
                <select name="province" id="province" class="form-control" formControlName="province" tabindex="6">
                  <option value="AB">Alberta</option>
                  <option value="BC">British Columbia</option>
                  <option value="MB">Manitoba</option>
                  <option value="NB">New Brunswick</option>
                  <option value="NL">Newfoundland and Labrador</option>
                  <option value="NS">Nova Scotia</option>
                  <option value="NT">Northwest Territories</option>
                  <option value="NU">Nunavut</option>
                  <option value="ON">Ontario</option>
                  <option value="PE">Prince Edward Island</option>
                  <option value="QC">Quebec</option>
                  <option value="SK">Saskatchewan</option>
                  <option value="YT">Yukon</option>
                </select>
              </div>

            </div>
            <div class="row">
              <mat-error class="text-center"
                *ngIf="updateGuestPatientFormSubmitted && updateGuestPatientFormControls.province.errors?.required">
                Province is required
              </mat-error>

            </div>
          </div>
        </div>
        <div class="col-lg-12">
          <div class="col-lg-4 col-md-4 col-sm-4">
            <div class="input-group">
              <span class="input-group-addon new-user-confirm-password-span">
                <img src="../../../../assets/img/Street Number.png" class="streetnumber-icon">
              </span>
              <div class="form-group label-floating">
                <label class="control-label addpatientlabel">Street Number
                  <small>(required)</small></label>
                <input name="streetNumber" type="text" class="form-control" formControlName="streetNumber" tabindex="7">
              </div>

            </div>
            <div class="row">
              <mat-error class="text-center"
                *ngIf="updateGuestPatientFormSubmitted && updateGuestPatientFormControls.streetNumber.errors?.required">
                Street Number is
                required</mat-error><br>
              <mat-error class="text-center"
                *ngIf="updateGuestPatientFormSubmitted && updateGuestPatientFormControls.streetNumber.errors?.pattern">
                Invalid Street Number</mat-error>
            </div>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-4">
            <div class="input-group">
              <span class="input-group-addon new-user-confirm-password-span">
                <img src="../../../../assets/img/Street Name.png" class="streetname-icon">
              </span>
              <div class="form-group label-floating">
                <label class="control-label addpatientlabel">Street Name
                  <small>(required)</small></label>
                <input name="streetName" type="text" class="form-control" formControlName="streetName" tabindex="8">
              </div>

            </div>
            <div class="row">
              <mat-error class="text-center"
                *ngIf="updateGuestPatientFormSubmitted && updateGuestPatientFormControls.streetName.errors?.required">
                Street Name is required
              </mat-error>
              <mat-error class="text-center"
                *ngIf="updateGuestPatientFormSubmitted && updateGuestPatientFormControls.streetName.errors?.pattern">
                Invalid Street Name
              </mat-error>
            </div>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-4">
            <div class="input-group">
              <span class="input-group-addon new-user-confirm-password-span">
                <img src="../../../../assets/img/Unit Number.png" class="unitnumber-icon">
              </span>
              <div class="form-group label-floating">
                <label class="control-label addpatientlabel">Unit Number
                  <small>(if applicable)</small></label>
                <input name="unit" type="text" class="form-control" formControlName="unit" tabindex="9">
              </div>

            </div>
            <div class="row">
              <mat-error class="text-center"
                *ngIf="updateGuestPatientFormSubmitted && updateGuestPatientFormControls.unit.errors?.pattern">Invalid
                Unit Number
              </mat-error>

            </div>
          </div>
        </div>
        <div class="col-lg-12">
          <div class="col-lg-4 col-md-4 col-sm-4">
            <div class="input-group">
              <span class="input-group-addon new-user-confirm-password-span">
                <img src="../../../../assets/img/Postal Code.png" class="postalcode-icon">
              </span>
              <div class="form-group label-floating">
                <label class="control-label addpatientlabel">Postal Code
                  <small>(required)</small></label>
                <input name="postalCode" type="text" class="form-control" formControlName="postalCode" tabindex="10">
              </div>

            </div>
            <div class="row">
              <mat-error class="text-center"
                *ngIf="updateGuestPatientFormSubmitted && updateGuestPatientFormControls.postalCode.errors?.required">
                Postal Code is required
              </mat-error>
            </div>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-4">
            <div class="input-group">
              <span class="input-group-addon new-user-confirm-password-span">
                <img src="../../../../assets/img/City.png" class="city-icon">
              </span>
              <div class="form-group label-floating">
                <label class="control-label addpatientlabel">City
                  <small>(required)</small></label>
                <input name="city" type="text" class="form-control" formControlName="city" tabindex="5">
              </div>

            </div>
            <div class="row">
              <mat-error class="text-center"
                *ngIf=" updateGuestPatientFormSubmitted && updateGuestPatientFormControls.city.errors?.required">City
                is
                required</mat-error>
              <mat-error class="text-center"
                *ngIf=" updateGuestPatientFormSubmitted && updateGuestPatientFormControls.city.errors?.pattern">
                Invalid
                City Name
              </mat-error>
            </div>
          </div>

          <div class="col-lg-4 col-md-4 col-sm-4">
            <div class="input-group">
              <span class="input-group-addon new-user-confirm-password-span">

                <img src="../../../../assets/icons/Icons/business.png" class="business-icon-update" width="32">
              </span>
              <div class="form-group label-floating">
                <label class="control-label addpatientlabel">Ontario Health Card Number<small>(if
                    applicable)</small></label>
                <input name="medicalCard" type="text" class="form-control" formControlName="medicalCard" tabindex="12">
              </div>

            </div>
            <div class="row">
              <mat-error class="text-center"
                *ngIf="updateGuestPatientFormSubmitted && updateGuestPatientFormControls.medicalCard.errors?.required">
                Ontario Health Card Number is
                required</mat-error>

            </div>
          </div>
        </div>
        <div class="col-lg-12">
          <div class="col-lg-4 col-md-4 col-sm-4">
            <div class="input-group">
              <span class="input-group-addon new-user-confirm-password-span">
                <img src="../../../../assets/icons/Icons/business.png" class="business-icon-update" width="32">
              </span>
              <div class="form-group label-floating">
                <label class="control-label addpatientlabel">Ontario Health Card Version<small></small></label>
                <input name="medicalCardVersion" type="text" class="form-control" formControlName="medicalCardVersion"
                  tabindex="13" matTooltip="applicable for eye
                  exams
                  only">
              </div>

            </div>
            <div class="row">
              <mat-error class="text-center"
                *ngIf="updateGuestPatientFormSubmitted && updateGuestPatientFormControls.medicalCardVersion.errors?.pattern">
                Medical card version is invalid, you must use capital letters
              </mat-error>
            </div>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-4">
            <div class="input-group">
              <span class="input-group-addon new-user-confirm-password-span">
                <img src="../../../../assets/icons/Icons/business.png" class="business-icon-update" width="32">
              </span>
              <div class="form-group label-floating">
                <label class="control-label addpatientlabel">Ontario Health Card Expiry Date<small></small></label>
                <input name="medicalCardExp" type="month" class="form-control" formControlName="medicalCardExp"
                  tabindex="14" max="3000-01" matTooltip="applicable for eye
                  exams
                  only">
              </div>

            </div>
            <div class="row">
              <mat-error class="text-center"
                *ngIf="updateGuestPatientFormSubmitted && updateGuestPatientFormControls.medicalCardExp.errors?.required">
                Ontario Health Card Expiry Date
                is required
              </mat-error>
            </div>
          </div>

        </div>

        <div class="row">
          <div class="col-lg-4 col-lg-offset-4 col-sm-6 col-sm-offset-3" style="    display: grid;
          justify-content: center;">
            <div>
              <div>
                <button type="submit" class="btn btn-primary" style="background: #071D49;"
                  [disabled]="multiplePressing">submit</button>

              </div>
            </div>
          </div>
        </div>

      </form>

    </div>


    <h5 class="text-center" *ngIf="getPartInfo('CreateGuestPatient')?.Enabled">Please fill out the required information
      below and click
      on
      submit.
    </h5>
    <div class="row" *ngIf="getPartInfo('CreateGuestPatient')?.Enabled">
      <form [formGroup]="createGuestPatientForm" (ngSubmit)="onCreateGuestPatientFormSubmit()">
        <div class="col-lg-12" id="optician-contact-form">
          <div class="col-lg-4 col-md-4 col-sm-4">
            <div class="input-group">
              <span class="input-group-addon new-user-confirm-password-span">
                <img src="../../../../assets/img/First Name.png" class="firstname-icon">
              </span>
              <div class="form-group label-floating">
                <label class="control-label addpatientlabel">First Name
                  <small>(required)</small></label>
                <input name="firstname" type="text" class="form-control" formControlName="firstName" tabindex="1">
              </div>

            </div>
            <div class="row">
              <mat-error class="text-center"
                *ngIf="createGuestPatientFormSubmitted && createGuestPatientFormControls.firstName.errors?.required">
                First Name is required
              </mat-error>
              <mat-error class="text-center"
                *ngIf="createGuestPatientFormSubmitted && createGuestPatientFormControls.firstName.errors?.pattern">
                Invalid First Name
              </mat-error>
            </div>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-4">
            <div class="input-group">
              <span class="input-group-addon new-user-confirm-password-span">
                <img src="../../../../assets/img/Last Name.png" class="lastname-icon">
              </span>
              <div class="form-group label-floating">
                <label class="control-label addpatientlabel">Last Name
                  <small>(required)</small></label>
                <input name="lastName" type="text" class="form-control" formControlName="lastName" tabindex="2">
              </div>



            </div>
            <div class="row">
              <mat-error class="text-center"
                *ngIf="createGuestPatientFormSubmitted && createGuestPatientFormControls.lastName.errors?.required">
                Last
                Name is required
              </mat-error>
              <mat-error class="text-center"
                *ngIf="createGuestPatientFormSubmitted && createGuestPatientFormControls.lastName.errors?.pattern">
                Invalid
                Last
                Name
              </mat-error>
            </div>
          </div>

          <div class=" col-lg-4 col-md-4 col-sm-4">
            <div class="input-group">
              <span class="input-group-addon new-user-confirm-password-span">
                <img src="../../../../assets/icons/Icons/calender.png" width="32" class="calender-icon">

              </span>
              <div class="form-group label-floating">
                <label class="control-label addpatientlabel">Date of Birth
                  <small>(required)</small></label>
                <input name="dateOfBirth" type="date" class="form-control" formControlName="dateOfBirth" tabindex="3"
                  max="3000-01-01">
              </div>


            </div>
            <div class="row">

              <mat-error class="text-center"
                *ngIf="createGuestPatientFormSubmitted && createGuestPatientFormControls.dateOfBirth.errors?.required">
                Date of Birth is
                required</mat-error>
            </div>
          </div>
        </div>
        <div class="col-lg-12">
          <div class="col-lg-4 col-md-4 col-sm-4">
            <div class="input-group">
              <span class="input-group-addon new-user-confirm-password-span">
                <img src="../../../../assets/img/Email.png" class="email-icon">
              </span>
              <div class="form-group label-floating">
                <label class="control-label addpatientlabel">Email
                  <small>(required)</small></label>
                <input name="email" type="text" class="form-control" formControlName="email" tabindex="4">
              </div>


            </div>
            <div class="row">
              <mat-error class="text-center"
                *ngIf="createGuestPatientFormSubmitted && createGuestPatientFormControls.email.errors?.email">Invalid
                Email format
              </mat-error>
            </div>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-4">
            <div class="input-group">
              <span class="input-group-addon new-user-confirm-password-span">
                <img src="../../../../assets/img/Cell Phone.png" class="cellphone-icon">
              </span>
              <div class="form-group label-floating">
                <label class="control-label addpatientlabel">Cell Phone
                  <small>(required)</small></label>
                <input name="cell" type="text" class="form-control" formControlName="cell" tabindex="5"
                  mask="(000) 000-0000" placeholder="(xxx) xxx-xxxx" style="border-bottom: none;">
              </div>


            </div>
            <div class="row">
              <mat-error class="text-center"
                *ngIf="createGuestPatientFormSubmitted && createGuestPatientFormControls.cell.errors?.required">Cell
                Phone is required
              </mat-error>
              <mat-error class="text-center"
                *ngIf="createGuestPatientFormSubmitted && createGuestPatientFormControls.cell.errors?.pattern">Cell
                Phone format should be
                xxx-xxx-xxxx
              </mat-error>
            </div>
          </div>


          <div class="col-lg-4 col-md-4 col-sm-4">
            <div class="input-group">
              <span class="input-group-addon new-user-confirm-password-span">
                <img src="../../../../assets/img/City.png" class="city-icon">
              </span>
              <div class="form-group label-floating">
                <label class="control-label addpatientlabel">City
                  <small>(required)</small></label>
                <input name="city" type="text" class="form-control" formControlName="city" tabindex="6">
              </div>



            </div>
            <div class="row">
              <mat-error class="text-center"
                *ngIf="createGuestPatientFormSubmitted && createGuestPatientFormControls.city.errors?.required">City
                is
                required</mat-error>
              <mat-error class="text-center"
                *ngIf="createGuestPatientFormSubmitted && createGuestPatientFormControls.city.errors?.pattern">
                Invalid
                City Name
              </mat-error>
            </div>
          </div>
        </div>
        <div class="col-lg-12">
          <div class="col-lg-4 col-md-4 col-sm-4">
            <div class="input-group">
              <span class="input-group-addon new-user-confirm-password-span">
                <img src="../../../../assets/img/Province.png" class="province-icon">
              </span>
              <div class="form-group label-floating">
                <label class="control-label addpatientlabel">Province
                  <small>(required)</small></label>
                <select name="province" id="province" class="form-control" formControlName="province" tabindex="7">
                  <option value="AB">Alberta</option>
                  <option value="BC">British Columbia</option>
                  <option value="MB">Manitoba</option>
                  <option value="NB">New Brunswick</option>
                  <option value="NL">Newfoundland and Labrador</option>
                  <option value="NS">Nova Scotia</option>
                  <option value="NT">Northwest Territories</option>
                  <option value="NU">Nunavut</option>
                  <option value="ON">Ontario</option>
                  <option value="PE">Prince Edward Island</option>
                  <option value="QC">Quebec</option>
                  <option value="SK">Saskatchewan</option>
                  <option value="YT">Yukon</option>
                </select>
              </div>


            </div>
            <div class="row">
              <mat-error class="text-center"
                *ngIf="createGuestPatientFormSubmitted && createGuestPatientFormControls.province.errors?.required">
                Province is required
              </mat-error>
            </div>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-4">
            <div class="input-group">
              <span class="input-group-addon new-user-confirm-password-span">
                <img src="../../../../assets/img/Street Number.png" class="streetnumber-icon">
              </span>
              <div class="form-group label-floating">
                <label class="control-label addpatientlabel">Street Number
                  <small>(required)</small></label>
                <input name="streetNumber" type="text" class="form-control" formControlName="streetNumber" tabindex="8">
              </div>

            </div>
            <div class="row">
              <mat-error class="text-center"
                *ngIf="createGuestPatientFormSubmitted && createGuestPatientFormControls.streetNumber.errors?.required">
                Street Number is
                required</mat-error>
              <mat-error class="text-center"
                *ngIf="createGuestPatientFormSubmitted && createGuestPatientFormControls.streetNumber.errors?.pattern">
                Invalid Street Number</mat-error>
            </div>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-4">
            <div class="input-group">
              <span class="input-group-addon new-user-confirm-password-span">
                <img src="../../../../assets/img/Street Name.png" class="streetname-icon">
              </span>
              <div class="form-group label-floating">
                <label class="control-label addpatientlabel">Street Name
                  <small>(required)</small></label>
                <input name="streetName" type="text" class="form-control" formControlName="streetName" tabindex="9">
              </div>


            </div>
            <div class="row">
              <mat-error class="text-center"
                *ngIf="createGuestPatientFormSubmitted && createGuestPatientFormControls.streetName.errors?.required">
                Street Name is
                required</mat-error>
              <mat-error class="text-center"
                *ngIf="createGuestPatientFormSubmitted && createGuestPatientFormControls.streetName.errors?.pattern">
                Invalid Street Name </mat-error>
            </div>
          </div>
        </div>
        <div class="col-lg-12">
          <div class="col-lg-4 col-md-4 col-sm-4">
            <div class="input-group">
              <span class="input-group-addon new-user-confirm-password-span">
                <img src="../../../../assets/img/Unit Number.png" class="unitnumber-icon">
              </span>
              <div class="form-group label-floating">
                <label class="control-label addpatientlabel">Unit Number
                  <small>(if applicable)</small></label>
                <input name="unit" type="text" class="form-control" formControlName="unit" tabindex="10">
              </div>
            </div>
            <div class="row">
              <mat-error class="text-center"
                *ngIf="createGuestPatientFormSubmitted && createGuestPatientFormControls.unit.errors?.pattern">
                Invalid
                Unit
                Number
              </mat-error>
            </div>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-4">
            <div class="input-group">
              <span class="input-group-addon new-user-confirm-password-span">
                <img src="../../../../assets/img/Postal Code.png" class="postalcode-icon">
              </span>
              <div class="form-group label-floating">
                <label class="control-label addpatientlabel">Postal Code
                  <small>(required)</small></label>
                <input name="postalCode" type="text" class="form-control" formControlName="postalCode" tabindex="11">
              </div>

            </div>
            <div class="row">
              <mat-error class="text-center"
                *ngIf="createGuestPatientFormSubmitted && createGuestPatientFormControls.postalCode.errors?.required">
                Postal Code is
                required</mat-error>
            </div>
          </div>

          <div class="col-lg-4 col-md-4 col-sm-4">
            <div class="input-group">
              <span id="building-span" class="input-group-addon new-user-confirm-password-span">
                <img id="add-guest-patient-image" src="../../../../assets/icons/Icons/business.png" width="32"
                  class="business-icon">
              </span>
              <div class="form-group label-floating">
                <label class="control-label addpatientlabel">Ontario Health Card Number<small>(if
                    applicable)</small></label>
                <input name="medicalCard" type="text" class="form-control" formControlName="medicalCard" tabindex="12">
              </div>


            </div>
            <div class="row">
              <mat-error class="text-center"
                *ngIf="createGuestPatientFormSubmitted && createGuestPatientFormControls.medicalCard.errors?.required">
                Ontario Health Card Number is
                required</mat-error>

              <mat-error class="text-center">&nbsp;</mat-error>
            </div>
          </div>
        </div>
        <div class="col-lg-12">
          <div class="col-lg-4 col-md-4 col-sm-4">
            <div class="input-group">
              <span class="input-group-addon new-user-confirm-password-span">
                <img src="../../../../assets/icons/Icons/business.png" class="business-icon-update" width="32">
              </span>
              <div class="form-group label-floating">
                <label class="control-label addpatientlabel">Ontario Health Card Version<small>(applicable for eye
                    exams
                    only)</small></label>
                <input name="medicalCardVersion" type="text" class="form-control" formControlName="medicalCardVersion"
                  tabindex="13" matTooltip="applicable for eye
                  exams
                  only">
              </div>

            </div>
            <div class="row">
              <mat-error class="text-center"
                *ngIf="createGuestPatientFormSubmitted && createGuestPatientFormControls.medicalCardVersion.errors?.pattern">
                Medical card version is invalid, you must use capital letters
              </mat-error>
            </div>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-4">
            <div class="input-group">
              <span id="building-span" class="input-group-addon new-user-confirm-password-span">
                <img id="add-guest-patient-image" src="../../../../assets/icons/Icons/business.png" width="32"
                  class="business-icon">
              </span>
              <div class="form-group label-floating">
                <label class="control-label addpatientlabel">Ontario Health Card Expiry Date<small></small></label>
                <input name="medicalCardExp" type="month" class="form-control" formControlName="medicalCardExp"
                  tabindex="14" max="3000-01" matTooltip="applicable for eye
                  exams
                  only">
              </div>
            </div>

            <div class="row">
              <mat-error class="text-center"
                *ngIf="createGuestPatientFormSubmitted && createGuestPatientFormControls.medicalCardExp.errors?.required">
                Health Card &
                Expiry is required
              </mat-error>
              <mat-error class="text-center">&nbsp;</mat-error>
            </div>
          </div>

        </div>

        <div class="row">
          <div class="col-lg-4 col-lg-offset-4 col-sm-6 col-sm-offset-3" style="display: grid;justify-content: center;">
            <div>
              <div>
                <button type="submit" class="btn btn-primary" style="background: #071D49;"
                  [disabled]="multiplePressing">submit</button>
              </div>
            </div>
          </div>
        </div>
      </form>

    </div>



    <h5 class="text-center" *ngIf="getPartInfo('CreateNewPatient')?.Enabled">Please fill out the required information
      below and
      click
      on
      submit.
    </h5>
    <div class="row" *ngIf="getPartInfo('CreateNewPatient')?.Enabled">
      <form [formGroup]="createNewPatientForm" (ngSubmit)="onCreateNewPatientFormSubmit()">
        <div class="col-lg-12" id="optician-contact-form">
          <div class="col-lg-4 col-md-4 col-sm-4">
            <div class="input-group">
              <span class="input-group-addon new-user-confirm-password-span">
                <img src="../../../../assets/img/First Name.png" class="firstname-icon">
              </span>
              <div class="form-group label-floating">
                <label class="control-label addpatientlabel">First Name
                  <small>(required)</small></label>
                <input name="firstname" type="text" class="form-control" formControlName="firstName" tabindex="1">
              </div>


            </div>
            <div class="row">
              <mat-error class="text-center"
                *ngIf="createNewPatientFormSubmitted && createNewPatientFormControls.firstName.errors?.required">First
                Name is
                required</mat-error>
            </div>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-4">
            <div class="input-group">
              <span class="input-group-addon new-user-confirm-password-span">
                <img src="../../../../assets/img/Last Name.png" class="lastname-icon">
              </span>
              <div class="form-group label-floating">
                <label class="control-label addpatientlabel">Last Name
                  <small>(required)</small></label>
                <input name="lastName" type="text" class="form-control" formControlName="lastName" tabindex="2">
              </div>


            </div>
            <div class="row">
              <mat-error class="text-center"
                *ngIf="createNewPatientFormSubmitted && createNewPatientFormControls.lastName.errors?.required">Last
                Name is
                required</mat-error>
            </div>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-4">
            <div class="input-group">
              <span class="input-group-addon new-user-confirm-password-span">
                <img src="../../../../assets/icons/Icons/calender.png" width="32" class="calender-icon">
              </span>
              <div class="form-group label-floating">
                <label class="control-label addpatientlabel">Date of Birth
                  <small>(required)</small></label>
                <input name="dateOfBirth" type="date" class="form-control" formControlName="dateOfBirth" tabindex="3"
                  max="3000-01-01">
              </div>


            </div>
            <div class="row">
              <mat-error class="text-center"
                *ngIf="createNewPatientFormSubmitted && createNewPatientFormControls.dateOfBirth.errors?.required">Date
                of Birth is
                required</mat-error>
            </div>
          </div>
        </div>
        <div class="col-lg-12">
          <div class="col-lg-4 col-md-4 col-sm-4">
            <div class="input-group">
              <span class="input-group-addon new-user-confirm-password-span">
                <img src="../../../../assets/img/Email.png" class="email-icon">
              </span>
              <div class="form-group label-floating">
                <label class="control-label addpatientlabel">Email
                  <small>(required)</small></label>
                <input name="email" type="text" class="form-control" formControlName="email" tabindex="4">
              </div>


            </div>
            <div class="row">
              <mat-error class="text-center"
                *ngIf="createNewPatientFormSubmitted && createNewPatientFormControls.email.errors?.email">
                Invalid Email format
              </mat-error>
            </div>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-4">
            <div class="input-group">
              <span class="input-group-addon new-user-confirm-password-span">
                <img src="../../../../assets/img/Cell Phone.png" class="cellphone-icon">
              </span>
              <div class="form-group label-floating">
                <label class="control-label addpatientlabel">Cell Phone
                  <small>(required)</small></label>
                <input name="cell" type="text" class="form-control" formControlName="cell" tabindex="5"
                  mask="(000) 000-0000" placeholder="(xxx) xxx-xxxx" style="border-bottom: none;">
              </div>


            </div>
            <div class="row">
              <mat-error class="text-center"
                *ngIf="createNewPatientFormSubmitted && createNewPatientFormControls.cell.errors?.required">
                Cell Phone is required
              </mat-error>
              <mat-error class="text-center"
                *ngIf="createNewPatientFormSubmitted && createNewPatientFormControls.cell.errors?.pattern">Cell
                Phone format should
                be xxx-xxx-xxxx
              </mat-error>
            </div>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-4">
            <div class="input-group ">
              <span class="input-group-addon new-user-confirm-password-span">
                <img src="../../../../assets/img/Street Number.png" class="streetnumber-icon">
              </span>
              <div class="form-group label-floating">
                <label class="control-label addpatientlabel">Street Number
                  <small>(required)</small></label>
                <input name="streetNumber" type="text" class="form-control" formControlName="streetNumber" tabindex="6">
              </div>



            </div>
            <div class="row">
              <mat-error class="text-center"
                *ngIf="createNewPatientFormSubmitted && createNewPatientFormControls.streetNumber.errors?.required">
                Street Number
                is required</mat-error><br>
              <mat-error class="text-center"
                *ngIf="createNewPatientFormSubmitted && createNewPatientFormControls.streetNumber.errors?.pattern">
                Invalid Street Number
              </mat-error>

            </div>

          </div>
        </div>
        <div class="col-lg-12">
          <div class="col-lg-4 col-md-4 col-sm-4">
            <div class="input-group">
              <span class="input-group-addon new-user-confirm-password-span">
                <img src="../../../../assets/img/Street Name.png" class="streetname-icon">
              </span>
              <div class="form-group label-floating">
                <label class="control-label addpatientlabel">Street Name
                  <small>(required)</small></label>
                <input name="streetName" type="text" class="form-control" formControlName="streetName" tabindex="7">
              </div>



            </div>
            <div class="row">
              <mat-error class="text-center"
                *ngIf="createNewPatientFormSubmitted && createNewPatientFormControls.streetName.errors?.required">Street
                Name is
                required</mat-error>
              <mat-error class="text-center"
                *ngIf="createNewPatientFormSubmitted && createNewPatientFormControls.streetName.errors?.pattern">Invalid
                Street Name
              </mat-error>

            </div>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-4">
            <div class="input-group">
              <span class="input-group-addon new-user-confirm-password-span">
                <img src="../../../../assets/img/Unit Number.png" class="unitnumber-icon">
              </span>
              <div class="form-group label-floating">
                <label class="control-label addpatientlabel">Unit Number
                  <small>(if applicable)</small></label>
                <input name="unit" type="text" class="form-control" formControlName="unit" tabindex="8">
              </div>


            </div>
            <div class="row">
              <mat-error class="text-center"
                *ngIf="createNewPatientFormSubmitted && createNewPatientFormControls.unit.errors?.pattern">
                Invalid Unit Number
              </mat-error>

            </div>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-4">
            <div class="input-group">
              <span class="input-group-addon new-user-confirm-password-span">
                <img src="../../../../assets/img/City.png" class="city-icon">
              </span>
              <div class="form-group label-floating">
                <label class="control-label addpatientlabel">City
                  <small>(required)</small></label>
                <input name="city" type="text" class="form-control" formControlName="city" tabindex="9">
              </div>


            </div>
            <div class="row">
              <mat-error class="text-center"
                *ngIf="createNewPatientFormSubmitted && createNewPatientFormControls.city.errors?.required">
                City is required
              </mat-error>
              <mat-error class="text-center"
                *ngIf="createNewPatientFormSubmitted && createNewPatientFormControls.city.errors?.pattern">
                Invalid City Name
              </mat-error>

            </div>
          </div>
        </div>
        <div class="col-lg-12">
          <div class="col-lg-4 col-md-4 col-sm-4">
            <div class="input-group">
              <span class="input-group-addon new-user-confirm-password-span">
                <img src="../../../../assets/img/Province.png" class="province-icon">
              </span>
              <div class="form-group label-floating">
                <label class="control-label addpatientlabel">Province
                  <small>(required)</small></label>
                <select name="province" id="province" class="form-control" formControlName="province" tabindex="10">
                  <option value="AB">Alberta</option>
                  <option value="BC">British Columbia</option>
                  <option value="MB">Manitoba</option>
                  <option value="NB">New Brunswick</option>
                  <option value="NL">Newfoundland and Labrador</option>
                  <option value="NS">Nova Scotia</option>
                  <option value="NT">Northwest Territories</option>
                  <option value="NU">Nunavut</option>
                  <option value="ON">Ontario</option>
                  <option value="PE">Prince Edward Island</option>
                  <option value="QC">Quebec</option>
                  <option value="SK">Saskatchewan</option>
                  <option value="YT">Yukon</option>
                </select>
              </div>
            </div>
            <div class="row">
              <mat-error class="text-center"
                *ngIf="createNewPatientFormSubmitted && createNewPatientFormControls.province.errors?.required">Province
                is
                required</mat-error>
            </div>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-4">
            <div class="input-group">
              <span class="input-group-addon new-user-confirm-password-span">
                <img src="../../../../assets/img/Postal Code.png" class="postalcode-icon">
              </span>
              <div class="form-group label-floating">
                <label class="control-label addpatientlabel">Postal Code
                  <small>(required)</small></label>
                <input name="postalCode" type="text" class="form-control" formControlName="postalCode" tabindex="11">
              </div>
            </div>
            <div class="row">
              <mat-error class="text-center"
                *ngIf="createNewPatientFormSubmitted && createNewPatientFormControls.postalCode.errors?.required">Postal
                Code is
                required</mat-error>
            </div>
          </div>

        </div>
        <div class="col-lg-12">
          <div class="col-lg-4 col-md-4 col-sm-4">
            <div class="input-group">
              <span id="building-span" class="input-group-addon new-user-confirm-password-span">
                <img src="../../../../assets/icons/Icons/business.png" width="32" class="business-icon">
              </span>
              <div class="form-group label-floating">
                <label class="control-label addpatientlabel">Ontario Health Card Number<small>(if
                    applicable)</small></label>
                <input name="medicalCard" type="text" class="form-control" formControlName="medicalCard" tabindex="12">
              </div>


            </div>
            <div class="row">
              <mat-error class="text-center"
                *ngIf="createNewPatientFormSubmitted && createNewPatientFormControls.medicalCard.errors?.required">
                Insurance
                Company is required</mat-error>
              <mat-error> &nbsp;
              </mat-error>
            </div>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-4">
            <div class="input-group">
              <span id="building-span" class="input-group-addon new-user-confirm-password-span">
                <img src="../../../../assets/icons/Icons/business.png" width="32" class="business-icon">
              </span>
              <div class="form-group label-floating">
                <label class="control-label addpatientlabel">Ontario Health Card Version<small> </small></label>
                <input name="medicalCardVersion" type="text" class="form-control" formControlName="medicalCardVersion"
                  tabindex="13" matTooltip="applicable for eye
                  exams
                  only">
              </div>
            </div>

            <mat-error class="text-center"
              *ngIf="createNewPatientFormSubmitted && createNewPatientFormControls.medicalCardVersion.errors?.pattern">
              Medical card version is invalid, you must use capital letters
            </mat-error>
          </div>

          <div class="col-lg-4 col-md-4 col-sm-4">
            <div class="input-group">
              <span id="building-span" class="input-group-addon new-user-confirm-password-span">
                <img src="../../../../assets/icons/Icons/business.png" width="32" class="business-icon">
              </span>
              <div class="form-group label-floating">
                <label class="control-label addpatientlabel">Ontario Health Card Expiry Date<small></small></label>
                <input name="medicalCardExp" type="month" class="form-control" formControlName="medicalCardExp"
                  max="3000-01" tabindex="14" matTooltip="applicable for eye
                  exams
                  only">
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-12">
          <div class="col-lg-4 col-md-4 col-sm-4">
            <div class="input-group">
              <span class="input-group-addon new-user-confirm-password-span">
                <img src="../../../../assets/icons/Icons/lock.svg" width="32" class="password-icon">
              </span>
              <div class="form-group label-floating">
                <label class="control-label addpatientlabel">Password<small>(required)</small></label>
                <input type="{{cPasswordFieldType}}" class="form-control" formControlName="password" tabindex="15">
              </div>
            </div>
            <mat-error class="text-center"
              *ngIf="createNewPatientFormSubmitted && createNewPatientFormControls.password.errors?.required">Password
              is
              required</mat-error>
            <mat-error class="text-center"
              *ngIf="createNewPatientFormSubmitted && createNewPatientFormControls.password.errors?.pattern">
              Password should
              contains at least 1 numeric, 1
              special , 1 capital characters.</mat-error><br>
            <mat-error class="text-center"
              *ngIf="createNewPatientFormSubmitted && createNewPatientFormControls.password.errors?.minlength">
              Password should be
              at least 8 characters
            </mat-error>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-4">
            <div class="input-group">
              <span class="input-group-addon new-user-confirm-password-span">
                <img src="../../../../assets/icons/Icons/lock.svg" width="32" class="password-icon">
              </span>
              <div class="form-group label-floating">
                <label class="control-label addpatientlabel">Confirm Password<small>(required)</small></label>
                <input type="{{cPasswordFieldType}}" class="form-control" formControlName="passwordConfirm"
                  tabindex="16">
              </div>
            </div>

            <mat-error class="text-center"
              *ngIf="createNewPatientFormSubmitted && createNewPatientFormControls.passwordConfirm.errors?.required">
              Password
              Confirm is required</mat-error>
            <mat-error class="text-center"
              *ngIf="createNewPatientFormSubmitted && createNewPatientFormControls.passwordConfirm.errors?.mustMatch">
              Passwords
              must match</mat-error>

          </div>

        </div>

        <div class="row">
          <div id="buttons-confirm-div" class="col-lg-4 col-lg-offset-4 col-sm-6 col-sm-offset-3">
            <div>
              <button type="submit" class="btn btn-primary" style="background: #071D49;"
                [disabled]="multiplePressing">submit</button>

            </div>
            <div>
              <button type="button" class="btn btn-primary" style="background: white;"
                (click)="showPassword('CreateNewPatient')">
                <i class="material-icons"
                  style="color: black;">{{cPasswordFieldType=='password'?'visibility_off':'visibility'}}</i>
              </button>
            </div>
            <div>
              <button type="button" class="btn btn-secondary" (click)="redirectTo('Login')"
                [disabled]="multiplePressing">BACK TO LOGIN</button>
            </div>
          </div>
        </div>
      </form>
      <!-- [(visible)]="getPartErrors('CreateNewPatient',1)" -->
      <!-- <p-dialog header=" " [showHeader]="true" [(visible)]="getPartErrors('CreateNewPatient',1)" [modal]="true"
        [style]="{width: '50vw'}" [baseZIndex]="10000" [draggable]="true" [resizable]="true" [closable]="true"
        (onHide)="disablePartInfo('ForgotPassword')">
        <h5 class="text-center">
          The email or phone number is already registered, if you have an account please login instead.
        </h5>
      </p-dialog> -->
      <p-dialog header=" " [showHeader]="true" [(visible)]="getPartInfo('NewPatientErrorDialog').Enabled" [modal]="true"
        [style]="{width: '50vw'}" [baseZIndex]="10000" [draggable]="true" [resizable]="true" [closable]="true"
        (onHide)="disablePartInfo('NewPatientErrorDialog');">
        <h3 class="text-center">
          The email or phone number is already registered, if you are facing issues logging in please continue with
          creating a new account. Otherwise, please click on the Login button.
        </h3>
        <div style="display: flex;justify-content: center;">
          <button type="button" class="btn btn-secondary text-center" style="background: #071D49;"
            (click)="redirectTo('Login')">Login</button>
          <button type="button" class="btn btn-secondary text-center" style=" margin-left: 5px;"
            (click)="onAlreadyRegisteredDialogCreateAccountClick()">Create Account</button>
        </div>

      </p-dialog>
    </div>


  </div>

  <div class="row" *ngIf="spinnerEnabled">
    <div style="display: flex;justify-content: center;">
      <p-progressSpinner [style]="{width: '50px', height: '50px'}" styleClass="custom-spinner" strokeWidth="5"
        fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>
    </div>
  </div>
</div>
<p-toast></p-toast>




<p-dialog header=" " [showHeader]="true" [(visible)]="getPartInfo('ForgotPassword').Enabled" [modal]="true"
  [style]="{width: '50vw'}" [baseZIndex]="10000" [draggable]="true" [resizable]="true" [closable]="true"
  (onHide)="disablePartInfo('ForgotPassword')">
  <p style="text-align: center;">Please enter your cell phone number</p>
  <form [formGroup]="forgotPasswordForm" (ngSubmit)="onForgotPasswordFormSubmit()">
    <div class="flex-container-center-direction-column-forgot-password">
      <div class="input-group cell-item">
        <span id="forgot-password-cell-phone-image-span" class="input-group-addon">
          <img src="../../../../assets/img/Cell Phone.png" id="cellphone_img">
        </span>
        <div class="form-group label-floating">
          <label class="control-label addpatientlabel">Cell Phone Number
          </label>
          <input type="text" class="form-control" formControlName="cell" tabindex="1" mask="(000) 000-0000"
            placeholder="(xxx) xxx-xxxx" style="border-bottom: none!important;">
        </div>
        <mat-error *ngIf="forgotPasswordFormSubmitted && forgotPasswordFormControls.cell.errors?.required">Cell phone
          number is required
        </mat-error>
        <mat-error *ngIf="getPartErrors('ForgotPassword',1)">We're sorry. We arn't able to identify you given the
          information.</mat-error>
      </div>
      <div style="display: flex;justify-content: center;">
        <button type="submit" class="btn btn-secondary forgotpassword-submit-button"
          [disabled]="passwordMultiplePressing">submit</button>
      </div>

      <div *ngIf="passwordSpinnerEnabled">
        <p-progressSpinner [style]="{width: '50px', height: '50px'}" styleClass="custom-spinner" strokeWidth="5"
          fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>
      </div>
    </div>
  </form>
</p-dialog>


<p-dialog header=" " [showHeader]="true" [(visible)]="getPartInfo('ForgotPasswordSMSPin').Enabled" [modal]="true"
  [style]="{width: '50vw'}" [baseZIndex]="10000" [draggable]="true" [resizable]="true" [closable]="true"
  (onHide)="disablePartInfo('ForgotPasswordSMSPin')">
  <p style="text-align: center;">We sent a PIN code to your phone. Please enter it below to reset your password.
  </p>
  <form [formGroup]="smsPinForgotPasswordForm" (ngSubmit)="onsSmsPinForgotPasswordFormSubmit()">
    <div class="flex-container-center-direction-column-pindcode">
      <div class="input-group pincode-item">
        <span class="input-group-addon">
          <i class="material-icons" id="pin-code-icon">sms</i>
        </span>
        <div class="form-group label-floating">
          <label class="control-label addpatientlabel">Pin Code
          </label>
          <input type="text" class="form-control" formControlName="smsPinCodeResetPassword" tabindex="1"
            placeholder="XXXX" mask="0000">
        </div>
      </div>
      <div class="row" style="margin-top: 3%;margin-bottom: 3%;padding-left: 5%;padding-right: 5%;">
        <p style="text-align: center;">The pin code will expire after 2 minutes .
        </p>
        <p class=" text-center main-subtitle" *ngIf="smsPinTimerSeconds!==0">{{smsPinTimerSeconds/60 | floor|
          number: '2.0-0'}}:{{smsPinTimerSeconds%60 | number: '2.0-0'}}</p>
      </div>
      <div class="row" style="margin-top: 3%;margin-bottom: 3%;padding-left: 5%;padding-right: 5%;">
        <mat-error
          *ngIf="smsPinForgotPasswordFormSubmitted && smsPinForgotPasswordFormControls.smsPinCodeResetPassword.errors?.required">
          SMS
          Pin is required</mat-error>
        <mat-error style="font-size: 12px;" *ngIf="getPartErrors('ForgotPasswordSMSPin',1)">Invalid PIN code. Make sure
          you entered the
          correct code or send another one to your phone.</mat-error>
      </div>

      <div style="display: flex;justify-content: center;">
        <button type="submit" class="btn btn-secondary pincodeform-submit-button"
          [disabled]="passwordMultiplePressing">submit</button>
        <button type="button" class="btn btn-secondary" style="background-color: white; color: black;"
          *ngIf="smsPinTimerSeconds===0 "
          (click)="onForgotPasswordSmsPinResendButtonClick('ForgotPasswordSMSPin')">Resend Code</button>
      </div>
      <div *ngIf="passwordSpinnerEnabled">
        <p-progressSpinner [style]="{width: '50px', height: '50px'}" styleClass="custom-spinner" strokeWidth="5"
          fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>
      </div>
    </div>
  </form>
</p-dialog>


<p-dialog header=" " [showHeader]="true" [(visible)]="getPartInfo('ResetPassword').Enabled" [modal]="true"
  [style]="{width: '50vw'}" [baseZIndex]="10000" [draggable]="true" [resizable]="true" [closable]="true"
  (onHide)="disablePartInfo('ResetPassword')">
  <p style="text-align: center;">Please enter a new password</p>
  <form [formGroup]="resetPasswordForm" (ngSubmit)="onResetPasswordFormSubmit()">
    <div class="flex-container-center-direction-column-reset-password row" style="padding: 5%;">
      <div class="input-group reset-password-item col-lg-4">

        <span class="input-group-addon" id="forgot-password-new-password-span">
          <img src="../../../../assets/icons/Icons/lock.svg" width="32" class="password-icon-forgot">
        </span>
        <div id="forgot-password-new-password" class="form-group label-floating">
          <label class="control-label addpatientlabel">Password
          </label>
          <input type="{{passwordFieldType }}" class="form-control" formControlName="newPassword" tabindex="1">
        </div>
      </div>
      <div class="row" style="margin-top: 3%;margin-bottom: 3%;">
        <mat-error class="text-center"
          *ngIf="resetPasswordFormSubmitted && resetPasswordFormControls.newPassword.errors?.required">Password
          is
          required
        </mat-error>
        <mat-error class="text-center"
          *ngIf="resetPasswordFormSubmitted && resetPasswordFormControls.newPassword.errors?.pattern">Password
          should contains at
          least 1 numeric, 1
          special , 1 capital characters.</mat-error>
        <mat-error class="text-center"
          *ngIf="resetPasswordFormSubmitted && resetPasswordFormControls.newPassword.errors?.minlength">Password
          should be at least 8
          characters
        </mat-error>
      </div>
      <div class="input-group reset-password-item col-lg-4">
        <span class="input-group-addon" id="forgot-password-new-confirm-password-span">
          <img src="../../../../assets/icons/Icons/restart-alt.svg" width="32" class="password-icon-confirm">
        </span>
        <div class="form-group label-floating" id="forgot-password-new-confirm-password">
          <label class="control-label addpatientlabel">Password Confirm
          </label>
          <input type="{{passwordFieldType}}" class="form-control" formControlName="passwordConfirm" tabindex="2">
        </div>

      </div>
      <div class="row" style="margin-top: 3%;margin-bottom: 3%;">
        <div *ngIf="resetPasswordFormControls.passwordConfirm.errors">
          <mat-error *ngIf="resetPasswordFormControls.passwordConfirm.errors?.required">Password Confirm is required
          </mat-error>
          <mat-error *ngIf="resetPasswordFormControls.passwordConfirm.errors?.mustMatch">Passwords must match
          </mat-error>
        </div>
      </div>
      <div>
        <button type="button" class="btn btn-secondary show-password-button"
          (click)="showPassword('ResetPassword')">{{showPasswordButtonTitle}}</button>
      </div>
      <div>
        <button type="submit" class="btn btn-secondary reset-password-button"
          [disabled]="passwordMultiplePressing">Submit</button>
      </div>
      <mat-error style="font-size: 13px;
      margin-top: 2%;
      margin-bottom: 2%;" class="text-center" *ngIf="getPartErrors('ResetPassword',1)">The provided Pin code is
        expired,please on the button below to
        resend the sms Code</mat-error>
      <div *ngIf="getPartErrors('ResetPassword',1)">
        <button type="button" class="btn btn-secondary reset-password-button"
          (click)="onForgotPasswordSmsPinResendButtonClick('ResetPassword')">Resend SMS</button>
      </div>
      <div *ngIf="passwordSpinnerEnabled">
        <p-progressSpinner [style]="{width: '50px', height: '50px'}" styleClass="custom-spinner" strokeWidth="5"
          fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>
      </div>
    </div>
  </form>
</p-dialog>





<p-dialog header=" " [showHeader]="true" [(visible)]="getPartInfo('PatientSMSPin').Enabled" [modal]="true"
  [style]="{width: '50vw'}" [baseZIndex]="10000" [draggable]="true" [resizable]="true"
  [closable]="!passwordMultiplePressing" (onHide)="disablePartInfo('PatientSMSPin')">
  <p style="text-align: center;">We sent a PIN code to your phone. Please enter it below to create your account.
  </p>
  <form [formGroup]="createNewPatientSmsPinForm" (ngSubmit)="onCreateNewPatientSmsPinFormSubmit()">
    <div class="flex-container-center-direction-column-pindcode">
      <div class="input-group pincode-item">
        <span class="input-group-addon">
          <i class="material-icons" id="pin-code-icon">sms</i>
        </span>
        <div class="form-group label-floating">
          <label class="control-label addpatientlabel">Pin Code
          </label>
          <input type="text" class="form-control" formControlName="smsPinCode" tabindex="1" placeholder="XXXX"
            mask="0000">
        </div>
      </div>
      <div class="row" style="margin-top: 3%;margin-bottom: 3%;padding-left: 5%;padding-right: 5%;">
        <p style="text-align: center;">The pin code will expire after 2 minutes .
        </p>
        <p class=" text-center main-subtitle" *ngIf="smsPinTimerSeconds!==0">{{smsPinTimerSeconds/60 | floor|
          number: '2.0-0'}}:{{smsPinTimerSeconds%60 | number: '2.0-0'}}</p>
      </div>
      <div class="row" style="margin-top: 3%;margin-bottom: 3%;padding-left: 5%;padding-right: 5%;">
        <mat-error
          *ngIf="createNewPatientSmsPinFormSubmitted && createNewPatientSmsPinFormControls.smsPinCode.errors?.required">
          SMS
          Pin is required</mat-error>
        <mat-error style="font-size: 12px;" *ngIf="getPartErrors('PatientSMSPin',1)">Invalid PIN code. Make sure
          you entered the
          correct code or send another one to your phone.</mat-error>
      </div>

      <div style="display: flex;justify-content: center;">
        <button type="submit" class="btn btn-secondary pincodeform-submit-button"
          [disabled]="passwordMultiplePressing">submit</button>
        <button type="button" class="btn btn-secondary" style="background-color: white; color: black;"
          *ngIf="smsPinTimerSeconds===0 " (click)="onCreateNewPatientSmsPinFormResend()">Resend Code</button>
      </div>
      <div *ngIf="passwordSpinnerEnabled" style="display: flex;justify-content: center;">
        <p-progressSpinner [style]="{width: '50px', height: '50px'}" styleClass="custom-spinner" strokeWidth="5"
          fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>
      </div>
    </div>
  </form>
</p-dialog>

<p-dialog header=" " [showHeader]="true" [(visible)]="getPartInfo('NewPatientList').Enabled" [modal]="true"
  [style]="{width: '50vw'}" [baseZIndex]="10000" [draggable]="true" [resizable]="true"
  [closable]="!passwordMultiplePressing" (onHide)="disablePartInfo('NewPatientList')">
  <p style="text-align: center;">Please select a patient or create new one.
  </p>
  <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group"
    [(ngModel)]="selectedNewPatient" [disabled]="passwordMultiplePressing" (change)="onRadioChange()">
    <mat-radio-button class="example-radio-button" *ngFor="let patient of newPatientList" [value]="patient">
      {{patient.firstName+' '+ patient.lastName}}
    </mat-radio-button>
    <mat-radio-button class="example-radio-button" [value]="null">
      Create new patient
    </mat-radio-button>
  </mat-radio-group>
  <div style="display: flex;justify-content:center;">
    <button type="button" class="btn user-matching-submit-button" [disabled]="passwordMultiplePressing"
      (click)="onNewPatientSelect()">Next
    </button>
  </div>

  <div *ngIf="passwordSpinnerEnabled" style="display: flex;justify-content: center;">
    <p-progressSpinner [style]="{width: '50px', height: '50px'}" styleClass="custom-spinner" strokeWidth="5"
      fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>
  </div>
</p-dialog>
