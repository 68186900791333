<!-- <div class="tab-pane" id="appointment-type"> -->
    <h4 class="info-text">Service Type</h4>
    <h4 class="info-text">Choose the service that you would like to book</h4>
    <div class="row">
        <div class="col-sm-10 col-sm-offset-1">
            <div class="col-sm-4">
                <div class="choice" data-toggle="wizard-radio" rel="tooltip"
                    title="Book an appointment with an optometrist only"
                    (click)="onOpticianOptimitristFormSubmit('optometrist')">
                    <input type="radio" name="job" value="Design">
                    <div class="icon">
                        <i class="material-icons">medication</i>
                    </div>
                    <h6>Optometrist Appointment</h6>
                </div>
            </div>
            <div class="col-sm-4">
                <div class="choice" data-toggle="wizard-radio" rel="tooltip"
                    title="Book an appointment with an optician only"
                    (click)="onOpticianOptimitristFormSubmit('opticians')">
                    <input type="radio" name="job" value="Code">
                    <div class="icon">
                        <i class="material-icons">find_replace</i>
                    </div>
                    <h6>Opticians Appointment</h6>
                </div>
            </div>
            <div class="col-sm-4">
                <div class="choice" data-toggle="wizard-radio" rel="tooltip"
                    title="Book an appointment with an optometrist & optician"
                    (click)="onOpticianOptimitristFormSubmit('both')">
                    <input type="radio" name="job" value="Code">
                    <div class="icon">
                        <i class="material-icons">person_search</i>
                    </div>
                    <h6>Optometrist and Optician Appointment</h6>
                </div>
            </div>
        </div>
    </div>
    <br>
    <div class="row">
        <div class="col-md-8">
            <div style="width: 100%">
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2800.532816985996!2d-75.69317238448114!3d45.418759679100376!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4cce05a9635628f5%3A0x126623ec6dff4430!2sEyemaxx%20Optical%20Studio!5e0!3m2!1sen!2sat!4v1619019877515!5m2!1sen!2sat"
                    width="100%" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
            </div>
            <a target="_blank" class="btn btn-next btn-hello btn-fill btn-danger btn-wd"
                href="https://www.google.com/maps/place/Eyemaxx+Optical+Studio/@45.4187597,-75.6931724,17z/data=!3m2!4b1!5s0x4cce05a97c9f1ddf:0xe0b6f9110f255594!4m5!3m4!1s0x4cce05a9635628f5:0x126623ec6dff4430!8m2!3d45.4187597!4d-75.6909837">Get
                Directions</a>
        </div>
        <div class="col-md-4">
            <h3>Eyemaxx Store Optical Studio</h3>
            <p>Address: 237 Elgin St Ottawa, ON K2P 1L6
            </p>
            <p>Email: staff@eyemaxx.ca</p>
            <p>Phone: 613.216.6076</p>
        </div>
    </div>
<!-- </div> -->