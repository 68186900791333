import { switchMap, take } from 'rxjs/operators';
import { StateManagementService } from './../../../services/state-management.service';
import { Component, OnInit, enableProdMode } from '@angular/core';
import { Form, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CreatePatientBody } from '../../models/create-patient/create-patient-body';
import { Patient } from '../../models/create-patient/patient';
import { SinglePatient } from '../../models/get-patients/patient';
import { PatientListResponse } from '../../models/get-patients/patient-list-response';
import { Step } from '../../models/Step/step';
import { AppointmentConfirmationData } from '../../models/Steps-Data/appointment-confirmation-data';
import { UpdateBody } from '../../models/update-patient-profile/update-body';
import { BookingModuleService } from '../../services/booking-module-service.service';
import { StepsManagementService } from '../../services/steps-management.service';
import { MessageService } from 'primeng/api';
import { PrimeNGConfig } from 'primeng/api';
import { environment } from 'src/environments/environment';
import { CreatePatientProfile, ForgotPasswordRequest, NewUserBookAppointment, PatientLoginRequest, ResetPasswordRequest, SendTokenPatient, TokenBeforeAppointment, ULPatient, UserLoginResponse, ValidateSMSPinRequest } from '../../models/4thNewImplementation';
import { MustMatch } from '../../helpers/password-validator';
import { observable, Observable, of, Subscription, timer } from 'rxjs';
import { concatMap, finalize, map } from 'rxjs/operators';
@Component({
  selector: 'app-appointmentconfirmation',
  templateUrl: './appointmentconfirmation.component.html',
  styleUrls: ['./appointmentconfirmation.component.css']
})
export class AppointmentconfirmationComponent implements OnInit {
  // 1.component constructor
  constructor(private wink: BookingModuleService,
    private steps: StepsManagementService,
    private fb: FormBuilder,
    private messageService: MessageService,
    private primengConfig: PrimeNGConfig,
    private state: StateManagementService) {
    this.spinnerEnabled = true;
    this.multiplePressing = true;
    this.state.clearParts();
    this.state.clearPartsErrors();
    let s: AppointmentConfirmationData = <AppointmentConfirmationData>this.steps.stepsData.filter(x => x.order == 4)[0];
    if (!s) {
      this.steps.currentStep = new Step(4, 'AppointmentConfirmation', false, true, false, 'confirmation');
      this.state.enablePart('UserScenario');
      this.spinnerEnabled = false;
      this.multiplePressing = false;
    } else {
      this.steps.currentStep = new Step(4, 'AppointmentConfirmation', false, true, true, 'confirmation');
      this.patientList.patientList = s.UsersList.patientList;
      this.selectedPatientInfo = Object.assign(this.selectedPatientInfo, s.SelectedUser);
      this.selectedPatientId = s.SelectedPatientId;
      this.existingPatientCell = this.selectedPatientInfo.cell;
      this.state.clearParts();
      this.state.enablePart('PatientList');
      this.spinnerEnabled = false;
      this.multiplePressing = false;
    }


  }
  /*  component initialize  */
  ngOnInit(): void {
    this.primengConfig.ripple = true;


    /* initialize forms  */
    this.loginForm = this.fb.group({
      loginEmail: ['', Validators.compose([Validators.required, Validators.minLength(10)])],
      password: ['', Validators.compose([Validators.required])],
      accountsId: [environment.accountsId]
    });

    this.createGuestPatientForm = this.fb.group({
      firstName: ['', Validators.compose([Validators.required, Validators.pattern('[a-zA-Zàâçéèêëîïôûùüÿñæœ ]+[ ]*')])],
      lastName: ['', Validators.compose([Validators.required, Validators.pattern('[a-zA-Zàâçéèêëîïôûùüÿñæœ ]+[ ]*')])],
      streetNumber: ['', Validators.compose([Validators.required,])],
      unit: ['', Validators.compose([])],
      streetName: ['', Validators.compose([Validators.required, Validators.pattern('[a-zA-Zàâçéèêëîïôûùüÿñæœ ]+[ ]*')])],
      city: ['', Validators.compose([Validators.required, Validators.pattern('[a-zA-Zàâçéèêëîïôûùüÿñæœ ]+[ ]*')])],
      province: ['', Validators.required],
      postalCode: ['', Validators.required],
      dateOfBirth: ['', Validators.required],
      cell: ['', Validators.compose([Validators.required])],
      email: ['', Validators.email],
      medicalCard: ['',],
      medicalCardExp: ['',],
      medicalCardVersion: ['', Validators.compose([Validators.pattern('[A-Z]{2}')])],
    });

    this.updateGuestPatientForm = this.fb.group({
      firstName: ['', Validators.compose([Validators.required, Validators.pattern('[a-zA-Zàâçéèêëîïôûùüÿñæœ ]+[ ]*')])],
      lastName: ['', Validators.compose([Validators.required, Validators.pattern('[a-zA-Zàâçéèêëîïôûùüÿñæœ ]+[ ]*')])],
      streetNumber: ['', Validators.compose([Validators.required,])],
      unit: ['', Validators.compose([])],
      streetName: ['', Validators.compose([Validators.required, Validators.pattern('[a-zA-Zàâçéèêëîïôûùüÿñæœ ]+[ ]*')])],
      city: ['', Validators.compose([Validators.required, Validators.pattern('[a-zA-Zàâçéèêëîïôûùüÿñæœ ]+[ ]*')])],
      province: ['', Validators.required],
      postalCode: ['', Validators.required],
      dateOfBirth: ['', Validators.required],
      cell: ['', Validators.compose([Validators.required])],
      email: ['', Validators.email],
      medicalCard: ['',],
      medicalCardExp: ['',],
      medicalCardVersion: ['', Validators.compose([Validators.pattern('[A-Z]{2}')])],
    });


    this.forgotPasswordForm = this.fb.group({
      cell: ['', Validators.required],
      accountsId: [environment.accountsId],
      originLink: 'https://onlinebookingv2.downloadwink.com/?id=1922&name=Eyemaxx%20Optical%20Studio',
      email: ['']
    });


    this.smsPinForgotPasswordForm = this.fb.group({
      smsPinCodeResetPassword: ['', Validators.required],
      accountsId: [environment.accountsId],
      cell: ['',]

    });


    this.resetPasswordForm = this.fb.group({
      newPassword: ['', Validators.compose([Validators.required])],
      passwordConfirm: ['', Validators.compose([Validators.required])],
      accountsId: ['',],
      token: ['',],
      emailOrCell: ['',],
      ipAddress: ['',],
    }, {
      validators: MustMatch('newPassword', 'passwordConfirm')
    });


    this.createNewPatientForm = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      streetNumber: ['', Validators.compose([Validators.required,])],
      unit: ['', Validators.compose([])],
      streetName: ['', Validators.compose([Validators.required,])],
      city: ['', Validators.compose([Validators.required,])],
      province: ['', Validators.required],
      postalCode: ['', Validators.required],
      dateOfBirth: ['', Validators.required],
      cell: ['', Validators.compose([Validators.required])],
      email: ['', Validators.email],
      medicalCard: ['',],
      medicalCardExp: ['',],
      medicalCardVersion: ['', Validators.compose([Validators.pattern('[A-Z]{2}')])],
      // password: ['', Validators.compose([Validators.required, Validators.minLength(8),
      // Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{8,}')])],
      password: ['', Validators.compose([Validators.required,])],
      passwordConfirm: ['', Validators.compose([Validators.required])]
    }, {
      validator: MustMatch('password', 'passwordConfirm')
    });

    this.createNewPatientSmsPinForm = this.fb.group({
      smsPinCode: ['', Validators.required],
    });


  }
  onRadioChange() {
    console.log(this.selectedNewPatient);

  }

  /* component state start */

  spinnerEnabled: boolean = false;
  multiplePressing: boolean = false;
  existingPatientCell: string = '';
  selectedPatientId: number = 0;

  passwordSpinnerEnabled: boolean = false;
  passwordMultiplePressing: boolean = false;
  //  form state start
  loginForm!: FormGroup;
  loginFormSubmitted: boolean = false;

  createGuestPatientForm!: FormGroup;
  createGuestPatientFormSubmitted: boolean = false;

  updateGuestPatientForm!: FormGroup;
  updateGuestPatientFormSubmitted: boolean = false;

  forgotPasswordCell: string = '';
  forgotPasswordPin: string = '';
  forgotPasswordResendBody!: ForgotPasswordRequest;
  smsPinTimerSeconds: number = 0;
  countDownStart: number = 120;
  smsPinTimerObservable: Observable<number> = timer(1000, 1000).pipe(map((x: number) => {
    return this.countDownStart - x;
  }), take(this.countDownStart + 1));
  smsPinTimerSubscription!: Subscription;

  forgotPasswordForm!: FormGroup;
  forgotPasswordFormSubmitted: boolean = false;


  smsPinForgotPasswordForm!: FormGroup;
  smsPinForgotPasswordFormSubmitted: boolean = false;


  resetPasswordForm!: FormGroup;
  resetPasswordFormSubmitted: boolean = false;
  passwordFieldType: string = 'password';
  showPasswordButtonTitle: string = 'Show Password';

  forgotPasswordProcessVariables: boolean[] = [false, false, false];


  createNewPatientForm!: FormGroup;
  createNewPatientFormSubmitted: boolean = false;
  cPasswordFieldType: string = 'password';
  cShowPasswordButtonTitle: string = 'Show Password';

  //create new patient sms pin state
  createNewPatientSmsPinForm!: FormGroup;
  createNewPatientSmsPinFormSubmitted: boolean = false;
  sendTokenBody!: TokenBeforeAppointment;
  createPatientProfileBody!: CreatePatientProfile;
  createPatientPin: string = '';


  newPatientList: SendTokenPatient[] = [];
  selectedNewPatient: SendTokenPatient = new SendTokenPatient();





  showPassword = (_type: string) => {
    if (_type == 'ResetPassword') {
      this.passwordFieldType = this.passwordFieldType == 'password' ? 'text' : 'password';
      this.showPasswordButtonTitle = this.showPasswordButtonTitle == 'Show Password' ? 'Hide Password' : 'Show Password';
    }
    else {
      this.cPasswordFieldType = this.cPasswordFieldType == 'password' ? 'text' : 'password';
      this.cShowPasswordButtonTitle = this.cShowPasswordButtonTitle == 'Show Password' ? 'Hide Password' : 'Show Password';
    }

  }
  get loginFormControls() {
    return this.loginForm.controls;
  }

  get createGuestPatientFormControls() {
    return this.createGuestPatientForm.controls;
  }

  get updateGuestPatientFormControls() {
    return this.updateGuestPatientForm.controls;
  }

  get forgotPasswordFormControls() {
    return this.forgotPasswordForm.controls;
  }

  get smsPinForgotPasswordFormControls() {
    return this.smsPinForgotPasswordForm.controls;
  }

  get resetPasswordFormControls() {
    return this.resetPasswordForm.controls;
  }

  get createNewPatientFormControls() {
    return this.createNewPatientForm.controls;
  }

  get createNewPatientSmsPinFormControls() {
    return this.createNewPatientSmsPinForm.controls;
  }

  //  form state end


  /* component state end */

  /*  API and data transfer objects start */
  patientList: PatientListResponse = new PatientListResponse();
  selectedPatientInfo: Patient = new Patient();
  /*  API and data transfer objects end */

  /* accessing user logic parts from state management service  */
  getPartInfo = (_name: string) => {
    let index: number = this.state.userLogicParts.findIndex(x => x.Name === _name);
    return this.state.userLogicParts[index];
  }

  getPartErrors = (_name: string, _messageId: number) => {
    return this.state.userLogicParts.find(x => x.Name === _name)?.Messages[_messageId - 1];
  }

  disablePartInfo = (_name: string) => {
    this.state.disablePart(_name);
  }


  /* redirect from user scenario to other user logic parts */

  redirectTo = (_name: string) => {
    this.spinnerEnabled = true;
    this.multiplePressing = true;
    this.state.clearParts();
    this.state.disablePart('UserScenario');
    this.state.disablePart('Login');
    this.state.disablePart('CreateNewPatient');
    setTimeout(() => {
      this.loginFormSubmitted = false;
      this.state.disablePartErrors('Login');
      this.loginForm.reset();

      this.createNewPatientFormSubmitted = false;
      this.state.disablePartErrors('CreateNewPatient');
      this.createNewPatientForm.reset();

      this.state.enablePart(_name);
      this.spinnerEnabled = false;
      this.multiplePressing = false;
    }, 1000);
  }

  onLoginFormSubmit = () => {
    this.loginFormSubmitted = true;
    this.state.disablePartErrors('Login');
    if (this.loginForm.invalid)
      return;
    this.spinnerEnabled = true;
    this.multiplePressing = true;
    this.loginForm.disable();
    this.loginForm.patchValue({ accountsId: environment.accountsId });
    this.wink.UserLogin(this.loginForm.value).subscribe(loginResult => {
      if (typeof loginResult == 'number') {
        this.state.enablePartError('Login', loginResult);
        this.spinnerEnabled = false;
        this.multiplePressing = false;
        this.loginForm.enable();
      } else {
        this.existingPatientCell = this.loginForm.get('loginEmail')?.value;
        this.patientList = new PatientListResponse();
        this.wink.getPatientList('mobile', this.existingPatientCell).subscribe(result => {
          this.patientList.patientList = this.patientList.Initialize(result);
          localStorage.clear();
          localStorage.setItem('TOKEN', loginResult.Token);
          this.state.clearParts();
          this.state.enablePart('PatientList');
          this.spinnerEnabled = false;
          this.multiplePressing = false;
          this.loginForm.enable();
        })
      }
    });
  }

  onPatientSelect = (_patientId: number) => {
    this.selectedPatientId = _patientId;
    this.multiplePressing = true;
    this.spinnerEnabled = true;
    //disable create and update guest patient parts
    this.state.disablePart('CreateGuestPatient');
    this.state.disablePart('UpdateGuestPatient');
    this.steps.clearSteps(4);
    if (this.selectedPatientId != -1) {
      this.wink.getPatientById(this.selectedPatientId).subscribe(pInfo => {
        this.selectedPatientInfo = new Patient();
        this.selectedPatientInfo = this.selectedPatientInfo.Initialize(pInfo);
        console.log('the selected patient info is ', this.selectedPatientInfo);
        //state management to be added here.
        this.steps.currentStep.validated = true;
        this.steps.Steps[this.steps.Steps.findIndex(x => x.order == 4)].validated = true;
        let p: AppointmentConfirmationData = new AppointmentConfirmationData(4, 'AppointmentConfirmation');
        p.SelectedUser = new Patient();
        p.UsersList = new PatientListResponse();
        p.SelectedUser = Object.assign(p.SelectedUser, this.selectedPatientInfo);
        p.UsersList.patientList = this.patientList.patientList;
        p.SelectedPatientId = this.selectedPatientId;
        this.steps.stepsData.push(p);
        this.steps.Steps[this.steps.Steps.findIndex(x => x.order == this.steps.currentStep.order + 1)].enabled = true;
        this.multiplePressing = false;
        this.spinnerEnabled = false;
      })
    } else {
      setTimeout(() => {
        this.createGuestPatientForm.reset();
        this.createGuestPatientForm.get('cell')?.disable();
        this.createGuestPatientForm.patchValue({ cell: this.existingPatientCell });
        this.createGuestPatientFormSubmitted = false;
        this.state.disablePart('UpdateGuestPatient');
        this.state.enablePart('CreateGuestPatient');
        this.multiplePressing = false;
        this.spinnerEnabled = false;
      }, 1000);


    }
  }

  onCreateGuestPatientFormSubmit = () => {
    this.createGuestPatientFormSubmitted = true;
    this.steps.clearSteps(4);
    this.state.disablePart('UpdateGuestPatient');
    if (this.createGuestPatientForm.invalid)
      return;
    this.spinnerEnabled = true;
    this.multiplePressing = true;
    this.createGuestPatientForm.disable();
    this.selectedPatientInfo = new Patient();
    this.selectedPatientId = 0;
    let _body: CreatePatientBody = new CreatePatientBody();
    _body = Object.assign(_body, this.createGuestPatientForm.getRawValue());
    _body.isDefaultSms = true;
    this.wink.createPatient(_body).pipe(switchMap((val) => this.wink.getPatientList('mobile', this.existingPatientCell))).subscribe(pl => {
      this.patientList = new PatientListResponse();
      this.patientList.patientList = this.patientList.Initialize(pl);
      this.createGuestPatientForm.reset();
      this.createGuestPatientForm.enable();
      this.createGuestPatientForm.patchValue({ cell: this.existingPatientCell });
      this.createGuestPatientForm.get('cell')?.disable();
      this.createGuestPatientFormSubmitted = false;
      this.state.disablePart('CreateGuestPatient');
      this.messageService.add({ severity: 'success', summary: 'Succeed', detail: 'A new patient has been added.' });
      this.spinnerEnabled = false;
      this.multiplePressing = false;
    })

  }
  onUpdatePatientClickButton = (_patientId: number) => {
    this.spinnerEnabled = true;
    this.multiplePressing = true;
    this.updateGuestPatientFormSubmitted = false;
    this.createGuestPatientFormSubmitted = false;
    this.state.disablePart('CreateGuestPatient');
    this.state.disablePart('UpdateGuestPatient');
    this.wink.getPatientById(_patientId).subscribe(pInfo => {
      let p_info: Patient = new Patient();
      p_info = p_info.Initialize(pInfo);
      let b_date: Date = new Date();
      b_date = new Date(Date.parse(p_info.dateOfBirth));
      this.updateGuestPatientForm.reset();
      this.updateGuestPatientForm.patchValue({ ...p_info, dateOfBirth: p_info?.dateOfBirth ? b_date.toISOString().substring(0, 10) : null });
      this.updateGuestPatientForm.get('cell')?.disable();
      this.state.enablePart('UpdateGuestPatient');
      this.spinnerEnabled = false;
      this.multiplePressing = false;
    });
  }


  onUpdateGuestPatientFormSubmit = () => {
    this.updateGuestPatientFormSubmitted = true;
    this.steps.clearSteps(4);
    if (this.updateGuestPatientForm.invalid)
      return;
    this.spinnerEnabled = true;
    this.multiplePressing = true;
    this.updateGuestPatientForm.disable();
    let _body: UpdateBody = new UpdateBody();
    _body = Object.assign(_body, this.updateGuestPatientForm.getRawValue());
    _body.id = this.selectedPatientId;
    this.wink.updatePatientProfile(_body).pipe(switchMap((val) => this.wink.getPatientList('mobile', this.existingPatientCell))).subscribe(pl => {
      this.patientList = new PatientListResponse();
      this.patientList.patientList = this.patientList.Initialize(pl);
      this.updateGuestPatientForm.reset();
      this.updateGuestPatientForm.enable();
      this.updateGuestPatientForm.patchValue({ cell: this.existingPatientCell });
      this.updateGuestPatientForm.get('cell')?.disable();
      this.updateGuestPatientFormSubmitted = false;
      this.state.disablePart('UpdateGuestPatient');
      this.messageService.add({ severity: 'success', summary: 'Succeed', detail: 'Patient info updated successfully.' });
      this.spinnerEnabled = false;
      this.multiplePressing = false;
    });
  }


  onForgotPasswordLinkClick = () => {
    //resetting the forgot password process related variables;
    this.spinnerEnabled = true;
    this.multiplePressing = true;


    setTimeout(() => {
      //parts to be disabled
      this.state.disablePart('ForgotPasswordSMSPin');
      this.state.disablePart('ResetPassword');
      //parts errors to be disabled
      this.state.disablePartErrors('ForgotPassword');
      this.state.disablePartErrors('ForgotPasswordSMSPin');
      this.state.disablePartErrors('ResetPassword');
      //parts variables and other observables to be disabled

      this.forgotPasswordForm.reset();
      this.smsPinForgotPasswordForm.reset();
      this.resetPasswordForm.reset();
      this.forgotPasswordFormSubmitted = false;
      this.smsPinForgotPasswordFormSubmitted = false;
      this.resetPasswordFormSubmitted = false;
      this.forgotPasswordCell = '';
      this.forgotPasswordPin = '';
      this.forgotPasswordResendBody = new ForgotPasswordRequest();
      if (this.smsPinTimerSubscription && !this.smsPinTimerSubscription.closed) {
        this.unsubscribeToTimerObservable();
      }
      this.spinnerEnabled = false;
      this.multiplePressing = false;
      this.state.enablePart('ForgotPassword');
    }, 1000);



  }

  onForgotPasswordFormSubmit = () => {
    //forgot password form behavior
    this.forgotPasswordFormSubmitted = true;
    if (this.forgotPasswordForm.invalid)
      return;

    //other forms initialization start
    this.state.disablePartErrors('ForgotPassword');
    this.state.disablePartErrors('ForgotPasswordSMSPin');
    this.state.disablePartErrors('ResetPassword');
    //parts variables and other observables to be disabled
    this.smsPinForgotPasswordForm.reset();
    this.resetPasswordForm.reset();
    this.smsPinForgotPasswordFormSubmitted = false;
    this.resetPasswordFormSubmitted = false;
    this.forgotPasswordCell = '';
    this.forgotPasswordPin = '';
    this.forgotPasswordResendBody = new ForgotPasswordRequest();
    if (this.smsPinTimerSubscription && !this.smsPinTimerSubscription.closed) {
      this.unsubscribeToTimerObservable();
    }
    //other forms initialization end

    this.passwordSpinnerEnabled = true;
    this.passwordMultiplePressing = true;
    this.forgotPasswordForm.patchValue({
      accountsId: environment.accountsId, originLink: 'https://onlinebookingv2.downloadwink.com/?id=1922&name=Eyemaxx%20Optical%20Studio',
      email: ''
    });
    this.forgotPasswordForm.disable();
    this.wink.ForgotPassword(this.forgotPasswordForm.getRawValue()).subscribe(result => {
      if (result == true) {
        this.messageService.add({ severity: 'success', summary: 'Done', detail: 'An SMS Pin Code sent to your phone.' });
        this.subscribeToTimerObservable();
        this.forgotPasswordCell = this.forgotPasswordForm.getRawValue()?.cell;
        this.forgotPasswordResendBody = Object.assign(this.forgotPasswordResendBody, this.forgotPasswordForm.getRawValue());

        console.log('Forgot password cell is:', this.forgotPasswordCell);
        console.log('Forgot password cell body to resend:', this.forgotPasswordResendBody);

        //sms pin form to be initialized
        this.state.enablePart('ForgotPasswordSMSPin');
      }
      else {
        this.state.enablePartError('ForgotPassword', 1);
      }
      this.forgotPasswordForm.enable();
      this.passwordSpinnerEnabled = false;
      this.passwordMultiplePressing = false;
    });

  }


  onsSmsPinForgotPasswordFormSubmit = () => {
    this.smsPinForgotPasswordFormSubmitted = true;
    if (this.smsPinForgotPasswordForm.invalid)
      return;
    this.state.disablePartErrors('ForgotPasswordSMSPin');
    this.state.disablePartErrors('ResetPassword');

    this.resetPasswordForm.reset();
    this.resetPasswordFormSubmitted = false;

    this.forgotPasswordPin = '';

    this.passwordSpinnerEnabled = true;
    this.passwordMultiplePressing = true;
    this.smsPinForgotPasswordForm.patchValue({ accountsId: environment.accountsId, cell: this.forgotPasswordCell });
    this.smsPinForgotPasswordForm.disable();

    this.wink.ValidateSMSPinCode(this.smsPinForgotPasswordForm.getRawValue()).subscribe(result => {
      if (result == true) {
        if (this.smsPinTimerSubscription && !this.smsPinTimerSubscription.closed) {
          this.unsubscribeToTimerObservable();
        }
        this.messageService.add({ severity: 'success', summary: 'Done', detail: 'SMS Pin Code verified.' });
        this.forgotPasswordPin = this.smsPinForgotPasswordForm.getRawValue()?.smsPinCodeResetPassword;
        this.state.enablePart('ResetPassword');
      } else {
        this.state.enablePartError('ForgotPasswordSMSPin', 1);
      }
      this.passwordSpinnerEnabled = false;
      this.passwordMultiplePressing = false;
      this.smsPinForgotPasswordForm.enable();
    });
  }


  onResetPasswordFormSubmit = () => {
    this.resetPasswordFormSubmitted = true;
    if (this.resetPasswordForm.invalid)
      return;
    this.state.disablePartErrors('ResetPassword');
    this.resetPasswordForm.patchValue({ accountsId: environment.accountsId, token: this.forgotPasswordPin, emailOrCell: this.forgotPasswordCell, ipAddress: '135.19.198.194' });
    this.resetPasswordForm.disable();
    this.passwordSpinnerEnabled = true;
    this.passwordMultiplePressing = true;
    this.wink.ResetPassword(this.resetPasswordForm.getRawValue()).subscribe(result => {
      if (result == true) {
        this.messageService.add({ severity: 'success', summary: 'Done', detail: 'Your password has been successfully updated.' });


        this.state.disablePart('ForgotPassword');
        this.state.disablePart('ForgotPasswordSMSPin');
        this.state.disablePart('ResetPassword');
        //parts errors to be disabled
        this.state.disablePartErrors('ForgotPassword');
        this.state.disablePartErrors('ForgotPasswordSMSPin');
        this.state.disablePartErrors('ResetPassword');
        //parts variables and other observables to be disabled

        this.forgotPasswordForm.reset();
        this.smsPinForgotPasswordForm.reset();
        this.resetPasswordForm.reset();
        this.forgotPasswordFormSubmitted = false;
        this.smsPinForgotPasswordFormSubmitted = false;
        this.resetPasswordFormSubmitted = false;
        this.forgotPasswordCell = '';
        this.forgotPasswordPin = '';
        this.forgotPasswordResendBody = new ForgotPasswordRequest();
        if (this.smsPinTimerSubscription && !this.smsPinTimerSubscription.closed) {
          this.unsubscribeToTimerObservable();
        }


      } else {
        this.state.enablePartError('ResetPassword', 1);
      }
      this.resetPasswordForm.enable();
      this.passwordSpinnerEnabled = false;
      this.passwordMultiplePressing = false;
    });
  }


  onForgotPasswordSmsPinResendButtonClick = (_type: string) => {
    this.passwordSpinnerEnabled = true;
    this.passwordMultiplePressing = true;
    if (this.smsPinTimerSubscription && !this.smsPinTimerSubscription.closed) {
      this.unsubscribeToTimerObservable();
    }
    if (_type == 'ForgotPasswordSMSPin') {
      this.smsPinForgotPasswordForm.reset();
      this.smsPinForgotPasswordFormSubmitted = false;
      this.state.disablePartErrors('ForgotPasswordSMSPin');
      this.smsPinForgotPasswordForm.disable();
      this.wink.ForgotPassword(this.forgotPasswordResendBody).subscribe(result => {
        this.subscribeToTimerObservable();
        this.passwordSpinnerEnabled = false;
        this.passwordMultiplePressing = false;
        this.smsPinForgotPasswordForm.enable();
      });
    }
    else {
      this.resetPasswordForm.reset();
      this.resetPasswordFormSubmitted = false;
      this.state.disablePartErrors('ResetPassword');
      this.resetPasswordForm.disable();

      this.wink.ForgotPassword(this.forgotPasswordResendBody).subscribe(result => {
        this.subscribeToTimerObservable();
        this.passwordSpinnerEnabled = false;
        this.passwordMultiplePressing = false;
        this.resetPasswordForm.enable();
        this.state.disablePart('ResetPassword');
        this.state.enablePart('ForgotPasswordSMSPin');
        this.smsPinForgotPasswordFormSubmitted = false;
      });


    }
  }


  onCreateNewPatientFormSubmit = () => {
    this.createNewPatientFormSubmitted = true;
    this.state.disablePartErrors('CreateNewPatient');
    this.createPatientPin = '';
    if (this.smsPinTimerSubscription && !this.smsPinTimerSubscription.closed) {
      this.unsubscribeToTimerObservable();
    }
    if (this.createNewPatientForm.invalid)
      return;
    this.spinnerEnabled = true;
    this.multiplePressing = true;
    this.createNewPatientForm.disable();

    //initialize send token body
    this.sendTokenBody = new TokenBeforeAppointment();
    this.sendTokenBody.cell = this.createNewPatientForm.getRawValue().cell;
    this.sendTokenBody.email = this.createNewPatientForm.getRawValue().email;
    this.sendTokenBody.existingPatientId = '';
    this.sendTokenBody.password = this.createNewPatientForm.getRawValue().password;
    //initialize create patient profile body
    this.createPatientProfileBody = new CreatePatientProfile();
    Object.assign(this.createPatientProfileBody, this.createNewPatientForm.getRawValue());
    this.createPatientProfileBody.username = this.createNewPatientForm.getRawValue().cell;
    console.log('Send Token Body is ', this.sendTokenBody);
    console.log('Patient Profile Body is ', this.createPatientProfileBody);
    this.sendTokenBody.email = '';
    this.wink.SendTokenBeforeAppointment(this.sendTokenBody).subscribe(result => {
      if (typeof result == 'boolean') {
        if (result == false) {
          // this.state.enablePartError('CreateNewPatient', 1);
          this.state.enablePart('NewPatientErrorDialog');
          // setTimeout(() => {
          // this.sendTokenBody = new TokenBeforeAppointment();
          // this.createPatientProfileBody = new CreatePatientProfile();
          // this.redirectTo('Login');
          // }, 100);
        } else {
          this.state.enablePart('PatientSMSPin');
          this.state.disablePartErrors('PatientSMSPin');
          this.createNewPatientSmsPinFormSubmitted = false;
          this.createNewPatientSmsPinForm.reset();
          this.subscribeToTimerObservable();
        }
      } else {
        this.newPatientList = [];
        this.newPatientList = result;
        this.state.enablePart('NewPatientList');
      }

      this.spinnerEnabled = false;
      this.multiplePressing = false;
      this.createNewPatientForm.enable();
    });
  }

  onAlreadyRegisteredDialogCreateAccountClick = () => {
    this.state.disablePart('NewPatientErrorDialog');
    this.spinnerEnabled = true;
    this.multiplePressing = true;
    this.sendTokenBody.existingPatientId = '0';
    this.createPatientProfileBody.existingPatientId = '0';
    console.log('send token body', this.sendTokenBody);
    console.log('patient profile body', this.createPatientProfileBody);
    this.sendTokenBody.email = '';
    this.wink.SendTokenBeforeAppointment(this.sendTokenBody).subscribe(result => {
      if (this.smsPinTimerSubscription && !this.smsPinTimerSubscription.closed) {
        this.unsubscribeToTimerObservable();
      }
      this.state.enablePart('PatientSMSPin');
      this.state.disablePartErrors('PatientSMSPin');
      this.createNewPatientSmsPinFormSubmitted = false;
      this.createNewPatientSmsPinForm.reset();
      this.subscribeToTimerObservable();
      this.spinnerEnabled = false;
      this.multiplePressing = false;
    });

  }

  onNewPatientSelect = () => {
    this.passwordSpinnerEnabled = true;
    this.passwordMultiplePressing = true;
    this.state.disablePartErrors('NewPatientList');
    if (this.selectedNewPatient != null) {
      this.sendTokenBody.existingPatientId = this.selectedNewPatient.patientId.toString();
      this.createPatientProfileBody.existingPatientId = this.selectedNewPatient.patientId.toString();
    } else {
      this.sendTokenBody.existingPatientId = '0';
      this.createPatientProfileBody.existingPatientId = '0';
    }
    console.log('send token body', this.sendTokenBody);
    console.log('patient profile body', this.createPatientProfileBody);
    this.sendTokenBody.email = '';
    this.wink.SendTokenBeforeAppointment(this.sendTokenBody).subscribe(result => {
      if (this.smsPinTimerSubscription && !this.smsPinTimerSubscription.closed) {
        this.unsubscribeToTimerObservable();
      }
      if (typeof result == 'boolean' && result == true) {
        this.state.disablePart('NewPatientList');
        this.state.enablePart('PatientSMSPin');
        this.state.disablePartErrors('PatientSMSPin');
        this.createNewPatientSmsPinFormSubmitted = false;
        this.createNewPatientSmsPinForm.reset();
        this.subscribeToTimerObservable();
      } else {
        this.state.enablePartError('NewPatientList', 1);
      }
      this.passwordSpinnerEnabled = false;
      this.passwordMultiplePressing = false;
    });
  }

  onCreateNewPatientSmsPinFormResend = () => {
    this.state.disablePartErrors('PatientSMSPin');
    this.createNewPatientSmsPinFormSubmitted = false;
    this.createNewPatientSmsPinForm.reset();
    if (this.smsPinTimerSubscription && !this.smsPinTimerSubscription.closed) {
      this.unsubscribeToTimerObservable();
    }
    this.passwordSpinnerEnabled = true;
    this.passwordMultiplePressing = true;
    this.createNewPatientSmsPinForm.disable();
    this.sendTokenBody.email = '';
    this.wink.SendTokenBeforeAppointment(this.sendTokenBody).subscribe(result => {
      this.subscribeToTimerObservable();
      this.passwordSpinnerEnabled = false;
      this.passwordMultiplePressing = false;
      this.createNewPatientSmsPinForm.enable();
    });
  }


  onCreateNewPatientSmsPinFormSubmit = () => {
    this.createNewPatientSmsPinFormSubmitted = true;
    if (this.createNewPatientSmsPinForm.invalid)
      return;
    this.passwordSpinnerEnabled = true;
    this.passwordMultiplePressing = true;
    this.spinnerEnabled = true;
    this.multiplePressing = true;
    this.createNewPatientSmsPinForm.disable();
    console.log('send token body', this.sendTokenBody);
    console.log('patient profile body', this.createPatientProfileBody);
    this.createPatientProfileBody.smsPinCode = this.createNewPatientSmsPinForm.getRawValue().smsPinCode;
    this.wink.CreatePatientProfile(this.createPatientProfileBody).subscribe(phase1 => {
      if (phase1 == true) {
        this.state.clearParts();
        this.state.clearPartsErrors();
        let _body: PatientLoginRequest = new PatientLoginRequest();
        _body.loginEmail = this.createPatientProfileBody.cell;
        _body.password = this.createPatientProfileBody.password;
        this.wink.UserLogin(_body).subscribe(phase2 => {
          this.existingPatientCell = this.createPatientProfileBody.cell;
          this.patientList = new PatientListResponse();
          this.wink.getPatientList('mobile', this.existingPatientCell).subscribe(phase3 => {
            this.patientList.patientList = this.patientList.Initialize(phase3);

            this.state.enablePart('PatientList');
            if (this.smsPinTimerSubscription && !this.smsPinTimerSubscription.closed) {
              this.unsubscribeToTimerObservable();
            }
            this.createNewPatientForm.reset();
            this.createNewPatientFormSubmitted = false;
            this.createNewPatientSmsPinForm.reset();
            this.createNewPatientSmsPinFormSubmitted = false;
            this.newPatientList = [];
          });
        });
      } else {
        this.state.enablePartError('PatientSMSPin', 1);
      }
      this.passwordSpinnerEnabled = false;
      this.passwordMultiplePressing = false;
      this.spinnerEnabled = false;
      this.multiplePressing = false;
      this.createNewPatientSmsPinForm.enable();
    });
  }

  subscribeToTimerObservable = (): void => {
    this.smsPinTimerSubscription = this.smsPinTimerObservable.subscribe(seconds => {
      this.smsPinTimerSeconds = seconds;
      console.log('Subscription is done', this.smsPinTimerSeconds);
    });
  }
  unsubscribeToTimerObservable = (): void => {
    this.smsPinTimerSeconds = 0;
    this.smsPinTimerSubscription.unsubscribe();
    console.log('Subscription is canceled');
  }


}

